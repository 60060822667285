<template>
  <div>
    <RightSideMenu />
    <div class="main-wrapper anim-duration-long">
      <Home />
      <Concept />
      <SmartGoalsHead />
      <img src="@/assets/sections/wave.svg" alt="" />
    </div>
    <Usecases />
    <Benefits />
    <Testimonials />
    <Offers />
    <Extra />
    <Contact contact-form-info-text='Reach out to us to know more!' />
  </div>
</template>

<script>
import RightSideMenu from "@/components/RightSideMenu";
import Home from "@/components/sections/Home";
import Concept from "@/components/sections/Concept.vue";
import Usecases from "@/components/sections/Usecases.vue";
import Benefits from "@/components/sections/Benefits.vue";
import Testimonials from "@/components/sections/Testimonials.vue";
import SmartGoalsHead from "@/components/sections/SmartGoalsHead.vue";
import Offers from "@/components/sections/Offers.vue";
import Extra from "@/components/sections/Extra.vue";
import Contact from "@/components/sections/Contact.vue";

export default {
  name: "Main",
  components: {
    RightSideMenu,
    Home,
    Concept,
    SmartGoalsHead,
    Usecases,
    Benefits,
    Testimonials,
    Offers,
    Extra,
    Contact,
  },
};
</script>

<style lang="css">
.anim-duration-long {
  transition: all ease-in-out 2500ms;
}
.anim-duration-short {
  transition: all ease-in-out 500ms;
}
</style>
