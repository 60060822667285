<template>
  <div class="vertical-5-rem-margin">
    <section id="skillgames">
      <div class="container-fluid px-0">
        <div class="row g-0">
          <div class="col">
            <div class="ratio ratio-16x9">
              <iframe
                src="https://www.youtube.com/embed/4WQeiYBRhrY"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row my-5">
          <div class="col d-flex flex-column">
            <span class="skillgames-title font-xxl">
              SmartGoals: SkillGames
            </span>
            <span class="font-xl mt-3"
              >More than just a measurement system!</span
            >
            <hr class="mt-4" />
          </div>
        </div>

        <div class="row my-5">
          <div
            class="col-12 col-md-6 d-flex flex-column justify-content-center align-items-center"
          >
            <div class="box-title">
              <span class="font-m"> Measure real performance data </span>
            </div>
            <div class="box-details mt-4">
              <p>
                Collect individual performance data through the measurement of
                players’ results during standardized and custom training
                exercises powered by SmartGoals.
              </p>
              <p>
                Standardized exercises include 16 meters sprint, passing
                maestro, ninja agility and more.
              </p>
              <p>
                Create your own customized exercises by making the system
                tailored to your own needs and training philosophy.
              </p>
              <p>
                Quantify players' development journey in a gamified way,
                challenging them to keep training and striving for more.
              </p>
            </div>
          </div>
          <div
            class="col-12 col-md-6 d-flex justify-content-center align-items-center"
          >
            <div class="img">
              <img
                src="@/assets/sections/skillgames/skillgames-app-measure-1.jpg"
                class="img-fluid"
                alt="SkillGames App Measure"
              />
            </div>
          </div>
        </div>

        <div class="row my-5">
          <div
            class="col-12 col-md-6 d-flex justify-content-center align-items-center order-2 order-md-1 my-0 my-md-5"
          >
            <div class="img">
              <img
                src="@/assets/sections/skillgames/skillgames-spider-web.jpg"
                class="img-fluid"
                alt="SkillGames Spider Web"
              />
            </div>
          </div>
          <div
            class="col-12 col-md-6 d-flex flex-column justify-content-center align-items-center order-1 order-md-2 my-0 my-md-5"
          >
            <div class="box-title">
              <span class="font-m"> Track growth </span>
            </div>
            <div class="box-details mt-4">
              <p>
                SkillGames allows you to monitor the development of players'
                abilities for 6 different categories: speed, passing, dribbling,
                agility, reaction, and endurance.
              </p>
              <p>
                The platform helps to discover key insights. For example, who is
                the best dribbler, or who has the best reaction skills?
              </p>
              <p>
                SkillGames concludes whether players are improving over time,
                stagnating or declining.
              </p>
            </div>
          </div>
        </div>

        <div class="row my-5">
          <div
            class="col-12 col-md-6 d-flex flex-column justify-content-center align-items-center my-0 my-md-5"
          >
            <div class="box-title">
              <span class="font-m"> Compare results </span>
            </div>
            <div class="box-details mt-4">
              <p>
                Compare players against each other or to their very own
                performances in the past.
              </p>
              <p>
                Overview of players' ranking based on their average and specific
                skill scores.
              </p>
              <p></p>
              <p>See where your players rank in your team and club.</p>
            </div>
          </div>

          <div
            class="col-12 col-md-6 d-flex justify-content-center align-items-center my-0 my-md-5"
          >
            <div class="img">
              <img
                src="@/assets/sections/skillgames/skillgames-board.jpg"
                class="img-fluid"
                alt="SkillGames Board"
              />
            </div>
          </div>
        </div>

        <div class="row my-5">
          <div
            class="col-12 col-md-6 d-flex justify-content-center align-items-center order-2 order-md-1 my-0 my-md-5"
          >
            <div class="img">
              <img
                src="@/assets/sections/skillgames/skillgames-complete.jpg"
                class="img-fluid"
                alt="SkillGames Complete"
              />
            </div>
          </div>
          <div
            class="col-12 col-md-6 d-flex flex-column justify-content-center align-items-center order-1 order-md-2 my-0 my-md-5"
          >
            <div class="box-title">
              <span class="font-m"> Compete </span>
            </div>
            <div class="box-details mt-4">
              <p>
                Help players work harder towards achieving their dreams, by
                sharpening their competitive instinct.
              </p>
              <p>
                SkillGames stimulates the players' competitive spirit, and
                competition drives growth.
              </p>
              <p></p>
              <p>
                Challenge players to always give more during training and beat
                their personal and teammates' records.
              </p>
            </div>
          </div>
        </div>

        <div class="row my-5">
          <div
            class="col-12 col-md-6 d-flex flex-column justify-content-center align-items-center my-0 my-md-5"
          >
            <div class="box-title">
              <span class="font-m"> Gaming, Fun & Engagement </span>
            </div>
            <div class="box-details mt-4">
              <p>
                Each player receiving their own SkillCard just like in the game
                is guaranteed fun!
              </p>
              <p>
                Just like the professionals: the Ronaldos, the Messis, the Van
                Dijks, kids now have the opportunity to get that real-life
                videogame-like experience on the pitch.
              </p>
              <p></p>
            </div>
          </div>
          <div
            class="col-12 col-md-6 d-flex justify-content-center align-items-center my-0 my-md-5"
          >
            <div class="img">
              <img
                src="@/assets/sections/skillgames/skillgames-fun.jpg"
                class="img-fluid"
                alt="SkillGames Fun"
              />
            </div>
          </div>
        </div>

        <div class="row my-5">
          <div class="col">
            <span class="skillgames-benefits-title font-xxl">Benefits</span>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6 col-lg-3 d-flex flex-column">
            <div class="icon">
              <font-awesome-icon :icon="['fas', 'child']" size="4x" />
            </div>
            <div class="benefit-title mt-4">
              <span>Player</span>
            </div>
            <div class="benefit-details-list mt-4">
              <ul>
                <li>Own their custom SkillCard like their idols</li>
                <li>Track personal growth</li>
                <li>Compare and compete with friends</li>
                <li>Have fun while improving</li>
              </ul>
            </div>
          </div>

          <div class="col-12 col-md-6 col-lg-3 d-flex flex-column mt-4 mt-md-0">
            <div class="icon">
              <font-awesome-icon :icon="['fas', 'clipboard-list']" size="4x" />
            </div>
            <div class="benefit-title mt-4">
              <span>Coach</span>
            </div>
            <div class="benefit-details-list mt-4">
              <ul>
                <li>Track players' growth</li>
                <li>Discover new talents</li>
                <li>Design your own tests</li>
                <li>Challenge players</li>
                <li>
                  Adapt training sessions to strenghts and weaknesses of players
                </li>
              </ul>
            </div>
          </div>

          <div class="col-12 col-md-6 col-lg-3 d-flex flex-column mt-4 mt-lg-0">
            <div class="icon">
              <font-awesome-icon :icon="['fas', 'users']" size="4x" />
            </div>
            <div class="benefit-title mt-4">
              <span>Club</span>
            </div>
            <div class="benefit-details-list mt-4">
              <ul>
                <li>Gain insights in players' development</li>
                <li>Scout and market new talents</li>
                <li>Club and fan engagement using football cards 2.0</li>
              </ul>
            </div>
          </div>

          <div class="col-12 col-md-6 col-lg-3 d-flex flex-column mt-4 mt-lg-0">
            <div class="icon">
              <font-awesome-icon :icon="['fas', 'home']" size="4x" />
            </div>
            <div class="benefit-title mt-4">
              <span>Parents</span>
            </div>
            <div class="benefit-details-list mt-4">
              <ul>
                <li>Track your childrens growth</li>
                <li>A tool to get your kids active and engaged</li>
                <li>Ensured fun and entertainment</li>
              </ul>
            </div>
          </div>
        </div>

        <Tests />

        <div class="row" style="margin-top: 8rem">
          <div class="col">
            <span class="font-xl fst-italic"
              >Test your players with SkillGames! If interested, please contact
              us on the following form.</span
            >
            <Contact contact-form-info-text='Reach out to us to know more!' />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Contact from "../components/sections/Contact.vue";
import Tests from "../components/sections/Tests.vue";
export default {
  metaInfo: {
    title: "SmartGoals - SkillGames",
    titleTemplate: null,
  },
  components: {
    Tests,
    Contact,
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
<style scoped>
.benefit-details-list ul li {
  list-style: square !important;
  text-align: left !important;
}
.skillgames-benefits-title {
  text-transform: uppercase;
  position: relative;
}
.skillgames-benefits-title::after {
  content: "";
  position: absolute;
  left: 0;
  top: 55px;
  width: 50%;
  border-bottom: 3px solid #ed7009;
}
</style>
