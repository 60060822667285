<template>
  <div class="wrapper" style="height: 100%">
    <Header
      id="welcome-section"
      :visitFromBlogPage="true"
      style="position:fixed; width: 100%"
    />
    <transition appear>
      <div class="container">
        <div class="row mt-5">
          <div class="col-12 mt-5">
            <h4>
              Posts under the following label:
              {{ $route.params.labelName }}
            </h4>
          </div>
        </div>
        <div class="row row-cols-1 row-cols-md-3 g-4 mb-5">
          <div v-for="post of listOfPosts" :key="post.id" class="col">
            <div class="card h-100 mt-3 shadow-sm">
              <div class="card-header">
                <div
                  class="card-img-container"
                  style="entry-img"
                  :style="{
                    backgroundImage:
                      post.image == 'no-picture'
                        ? 'url(' + defaultImg + ')'
                        : 'url(' + post.image + ')',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center'
                  }"
                ></div>
                <div class="pt-3">
                  <a
                    class="category-link"
                    :href="'/blog/categories/' + post.category"
                    >{{ post.category }}</a
                  >
                </div>
              </div>
              <div class="card-body">
                <router-link :to="`/blog/posts/${post.url}`">
                  <h5 class="card-title">
                    {{ post.title }}
                  </h5>
                </router-link>
              </div>
              <div class="card-footer d-flex align-items-center">
                <div class="d-flex">
                  <img
                    :src="post.author.avatar"
                    class="author-avatar"
                    alt="Author avatar"
                  />
                </div>
                <div class="d-flex px-2">
                  <a :href="post.author.social" target="_blank"
                    ><span class="py-2">{{ post.author.name }}</span></a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";

// eslint-disable-next-line no-unused-vars
import defaultImage from "@/assets/default-blog-img.svg";

export default {
  components: {
    Header,
    Footer
  },
  data() {
    return {
      posts: null,
      defaultImg: null
    };
  },
  mounted() {
    this.$store.dispatch("listByLabel", this.$route.params.labelName);
    this.defaultImg = defaultImage;
  },
  computed: {
    listOfPosts() {
      return this.$store.getters.listByLabel;
    },
    capitalizeCategory(lowerCasePostCategory) {
      return (
        lowerCasePostCategory.charAt(0).toUpperCase() +
        lowerCasePostCategory.slice(1)
      );
    }
  }
};
</script>

<style scoped>
.wrapper {
  overflow: hidden;
}
.card-header {
  background-color: white;
  border: 0px;
}
a:any-link {
  color: #ed7009;
  text-decoration: none;
}
@media (max-width: 992px) {
  #welcome-section {
    box-shadow: 0px 2px 25px rgb(0, 0, 0, 0.05);
  }
}
.v-enter-active,
.v-leave-active {
  transition: opacity 2s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
.author-avatar {
  border-radius: 50%;
  width: 3rem;
}
.card-img-container {
  height: 13rem;
  border-radius: 8px;
}
.category-link {
  padding: 0.75rem;
  background: linear-gradient(to right, #131132, #131132);
  background-repeat: no-repeat;
  background-size: 0 100%;
  transition: background-size 0.5s 0s;
  font-weight: bold;
  border-radius: 8px;
}
.category-link:hover {
  background-size: 100% 100%;
}
.card-title {
  color: #131132;
}
</style>