<template>
  <section id="extra-section" class="vertical-5-rem-margin">
    <div class="container">
      <div class="row my-5">
        <div class="col d-flex justify-content-center">
          <span class="extra-title font-xxl"> Extra </span>
        </div>
      </div>
      <div class="row d-flex justify-content-center">
        <div
          class="col-12 extra-box col-lg-4 d-flex flex-column justify-content-between align-items-center"
        >
          <div class="extra-img">
            <img
              src="@/assets/sections/smartgoals/sm-trolley.webp"
              class="img-fluid"
              alt="SmartGoals Trolley"
            />
          </div>
          <div
            class="d-flex flex-column justify-content-center align-items-center mt-4"
          >
            <span class="d-flex font-m"> SmartGoals Trolley </span>
            <span class="d-flex">
              <a href="/#contact-section" class="sm-orange-btn my-2">Get an offer</a>
            </span>
          </div>
        </div>
        <div
          class="col-12 extra-box g-5 g-md-0 col-lg-4 d-flex flex-column justify-content-between align-items-center"
        >
          <div class="extra-img">
            <img
              src="@/assets/sections/smartgoals/sm-timing-frame.webp"
              class="img-fluid"
              alt="SmartGoals Timing Frame"
            />
          </div>
          <div
            class="d-flex flex-column justify-content-center align-items-center mt-4"
          >
            <span class="d-flex font-m"> SmartGoals Time Frame </span>
            <span class="d-flex">
              <a href="/#contact-section" class="sm-orange-btn my-2">Get an offer</a>
            </span>
          </div>
        </div>
        <div
          class="col-12 extra-box g-5 g-md-0 col-lg-4 d-flex flex-column justify-content-between align-items-center"
        >
          <div class="extra-img">
            <img
              src="@/assets/sections/mockups/combined-mockup.png"
              class="img-fluid"
              alt="SmartGoals Smart Trolley"
            />
          </div>
          <div
            class="d-flex flex-column justify-content-center align-items-center mt-4"
          >
            <span class="d-flex font-m"> SkillGames License </span>
            <span class="d-flex">
              <a href="/#contact-section" style="margin: 0.5rem 5px !important;" class="sm-orange-btn my-2">Get an offer</a>
              <a href="/skillgames" style="margin: 0.5rem 5px !important;" class="sm-orange-btn my-2">More info</a>
            </span>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {};
</script>
<style lang="css">
#extra-section {
  min-height: 25vh;
}
.extra-title {
  text-transform: uppercase;
  position: relative;
}
.extra-title::after {
  content: "";
  position: absolute;
  left: 0;
  top: 55px;
  width: 50%;
  border-bottom: 3px solid #ed7009;
}
.extra-box {
  max-height: 22rem;
  overflow: hidden;
  z-index: 12 !important;
}
.extra-img img {
  max-height: 15rem;
}
.extra-box a:any-link {
  text-decoration: none;
  color: #fff;
}
.extra-img {
  transition: all 500ms ease-in-out;
}
.extra-img:hover {
  transform: scale(1.1);
}
</style>
