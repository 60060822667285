import Vue from "vue";
import VueRouter from "vue-router";
import Main from "../views/Main.vue";
import FaqPage from "../pages/FaqPage.vue";
import Post from "../pages/Post.vue";
import Posts from "../pages/Posts.vue";
import FilterByCategory from "../pages/FilterByCategory.vue";
import FilterByLabel from "../pages/FilterByLabel.vue";
import SkillGamesPage from "../pages/SkillGamesPage.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Main",
    component: Main,
  },
  {
    path: "/skillgames",
    name: "SkillGames",
    component: SkillGamesPage,
  },
  {
    path: "/faq",
    name: "FAQ",
    component: FaqPage,
  },
  {
    path: "/blog/posts",
    name: "Posts",
    component: Posts,
    meta: {
      title: "SmartGoals Blog - Posts",
    },
  },
  {
    path: "/blog/posts/:entryUrl",
    name: "Post",
    component: Post,
  },
  {
    path: "/blog/categories/:categoryName",
    name: "FilterByCategory",
    component: FilterByCategory,
    meta: {
      title: "SmartGoals Blog - Order by Catgories",
    },
  },
  {
    path: "/blog/labels/:labelName",
    name: "FilterByLabel",
    component: FilterByLabel,
    meta: {
      title: "SmartGoals Blog - Order by Label",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
