var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[_c('Header',{staticStyle:{"position":"fixed","width":"100%"},attrs:{"id":"welcome-section","visitFromBlogPage":true}}),_c('div',{staticClass:"container"},[(_vm.isLoading)?_c('div',{staticClass:"d-flex justify-content-center align-items-center vh-100"},[_vm._m(0)]):_c('div',[_c('transition',{attrs:{"appear":""}},[_c('div',{staticClass:"row row-cols-1 row-cols-md-2 g-4 my-5"},_vm._l((_vm.listOfPosts),function(post){return _c('div',{key:post.id,staticClass:"col"},[_c('div',{staticClass:"card mt-3 shadow-sm"},[_c('router-link',{attrs:{"to":("/blog/posts/" + (post.url))}},[_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"card-img-container",staticStyle:{},style:({
                      backgroundImage:
                        post.image == 'no-picture'
                          ? 'url(' + _vm.defaultImg + ')'
                          : 'url(' + post.image + ')',
                      backgroundSize: 'cover',
                      backgroundPosition: 'center'
                    })}),_c('div',{staticClass:"pt-3"},[_c('a',{staticClass:"category-link",attrs:{"href":'/blog/categories/' + post.category}},[_vm._v(_vm._s(post.category))])])]),_c('div',{staticClass:"card-body d-flex align-items-start"},[_c('h5',{staticClass:"card-title"},[_vm._v(" "+_vm._s(post.title)+" ")])])]),_c('div',{staticClass:"card-footer d-flex align-items-center"},[_c('div',{staticClass:"d-flex"},[_c('img',{staticClass:"author-avatar",attrs:{"src":post.author.avatar,"alt":"Author avatar"}})]),_c('div',{staticClass:"d-flex px-2"},[_c('a',{attrs:{"href":post.author.social,"target":"_blank"}},[_c('span',[_vm._v(_vm._s(post.author.name))])])])])],1)])}),0)])],1)])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"spinner-border d-flex",staticStyle:{"width":"5rem","height":"5rem"},attrs:{"role":"status"}},[_c('span',{staticClass:"visually-hidden"},[_vm._v("Loading...")])])}]

export { render, staticRenderFns }