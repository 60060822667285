<template>
  <section id="offers-section" class="vertical-5-rem-margin">
    <div class="container-fluid">
      <div class="row my-5">
        <div class="col d-flex justify-content-center">
          <span class="offers-title font-xxl"> Offers </span>
        </div>
      </div>
      <div class="row d-flex justify-content-center">
        <div class="col-10 col-md-6">
          <div
            id="smartgoals-offers"
            class="carousel slide carousel-dark"
            data-bs-ride="carousel"
          >
            <div class="carousel-indicators">
              <button
                type="button"
                data-bs-target="#smartgoals-offers"
                data-bs-slide-to="0"
                class="active"
                aria-current="true"
                aria-label="Slide 1"
              ></button>
              <button
                type="button"
                data-bs-target="#smartgoals-offers"
                data-bs-slide-to="1"
                aria-label="Slide 2"
              ></button>
              <button
                type="button"
                data-bs-target="#smartgoals-offers"
                data-bs-slide-to="2"
                aria-label="Slide 3"
              ></button>
              <button
                type="button"
                data-bs-target="#smartgoals-offers"
                data-bs-slide-to="3"
                aria-label="Slide 4"
              ></button>
            </div>
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img
                  src="@/assets/sections/offers/basic-set.jpg"
                  class="d-block w-100"
                  alt="SmartGoals Basic"
                />
                <div class="carousel-caption">
                  <h5>SmartGoals Basic</h5>
                  <ul class="details">
                    <li><b>1 Charger</b></li>
                    <li><b>3 Football frames</b></li>
                    <li><b>6 Smartlights</b></li>
                  </ul>
                  <a class="sm-orange-btn my-2" href="/#contact-section">
                    Get an offer
                  </a>
                </div>
              </div>
              <div class="carousel-item">
                <img
                  src="@/assets/sections/offers/standard-set.jpg"
                  class="d-block w-100"
                  alt="SmartGoals Standard"
                />
                <div class="carousel-caption">
                  <h5>SmartGoals Standard</h5>
                  <ul class="details">
                    <li><b>1 Charger</b></li>
                    <li><b>4 Football frames</b></li>
                    <li><b>8 SmartLights</b></li>
                    <li><b>1 SmartRemote</b></li>
                  </ul>
                  <a class="sm-orange-btn my-2" href="/#contact-section">
                    Get an offer
                  </a>
                </div>
              </div>
              <div class="carousel-item">
                <img
                  src="@/assets/sections/offers/premium-set.png"
                  class="d-block w-100"
                  alt="SmartGoals Premium"
                />
                <div class="carousel-caption">
                  <h5>SmartGoals Premium</h5>
                  <ul class="details">
                    <li><b>1 Charger</b></li>
                    <li><b>6 Football frames</b></li>
                    <li><b>12 SmartLights</b></li>
                    <li><b>1 SmartRemote</b></li>
                  </ul>      
                  <a class="sm-orange-btn my-2" href="/#contact-section">
                    Get an offer
                  </a>
                </div>
              </div>
              <div class="carousel-item">
                <img
                  src="@/assets/sections/offers/timing-set.png"
                  class="d-block w-100"
                  alt="SmartGoals Timing"
                />
                <div class="carousel-caption">
                  <h5>SmartGoals Timing</h5>
                  <ul class="details">
                    <li><b>4 Timing poles</b></li>
                  </ul>
                  <a class="sm-orange-btn my-2" href="/#contact-section">
                    Get an offer
                  </a>
                </div>
              </div>
            </div>
            <button
              class="carousel-control-prev"
              type="button"
              data-bs-target="#smartgoals-offers"
              data-bs-slide="prev"
            >
              <span class="visually-hidden">Previous</span>
            </button>
            <button
              class="carousel-control-next"
              type="button"
              data-bs-target="#smartgoals-offers"
              data-bs-slide="next"
            >
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {};
</script>
<style lang="css">
.offers-title {
  text-transform: uppercase;
  position: relative;
}
.offers-title::after {
  content: "";
  position: absolute;
  left: 0;
  top: 55px;
  width: 50%;
  border-bottom: 3px solid #ed7009;
}
.carousel-caption {
  position: relative !important;
  left: auto !important;
  right: auto !important;
  margin: 2rem;
  padding-top: 2.25rem !important;
  padding-bottom: 2.25rem !important;
}
.carousel-caption a:any-link {
  text-decoration: none !important;
  color: #fff;
  margin-top: 2rem;
}
.carousel-caption .sm-orange-btn {
  margin: 0 !important;
}
.carousel-caption h5 {
  margin-bottom: 2rem;
}
.carousel-inner img {
  object-fit: fill;
}

.carousel-indicators {
  margin-bottom: 5rem !important;
}

.details {
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  width: 11rem;
}
</style>
