import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

Vue.config.productionTip = false;

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import VueScrollactive from "vue-scrollactive";
import VueMeta from "vue-meta";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faExpandArrowsAlt,
  faLightbulb,
  faBolt,
  faStopwatch20,
  faComments,
  faHeartbeat,
  faUsers,
  faEye,
  faQuoteLeft,
  faChild,
  faClipboardList,
  faHome,
} from "@fortawesome/free-solid-svg-icons";
import {
  faLinkedin,
  faInstagramSquare,
  faFacebookSquare,
  faYoutubeSquare,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(
  faExpandArrowsAlt,
  faLightbulb,
  faBolt,
  faStopwatch20,
  faComments,
  faHeartbeat,
  faUsers,
  faEye,
  faQuoteLeft,
  faLinkedin,
  faInstagramSquare,
  faFacebookSquare,
  faYoutubeSquare,
  faChild,
  faClipboardList,
  faUsers,
  faHome
);
Vue.use(VueScrollactive);
Vue.use(VueMeta);
Vue.component("font-awesome-icon", FontAwesomeIcon);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
