<template>
  <div id="app">
    <Header />
    <router-view />
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";

export default {
  metaInfo: {
    title: "SmartGoals - Main",
    titleTemplate: null,
  },
  components: {
    Header,
    Footer,
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style>
@font-face {
  font-family: Prompt-Medium;
  src: url("./assets/fonts/Prompt-Medium.ttf");
}
@font-face {
  font-family: Prompt-Regular;
  src: url("./assets/fonts/Prompt-Regular.ttf");
}
@font-face {
  font-family: Prompt-Light;
  src: url("./assets/fonts/Prompt-Light.ttf");
}
#app {
  font-family: Prompt-Light, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #fcfcfc;
}
.vertical-5-rem-margin {
  margin-top: 5rem;
  margin-bottom: 5rem;
}
.sm-orange-btn {
  color: #fff;
  background-color: #ed7009;
  border-radius: 40px;
  height: 44px;
  border: none;
  padding: 10px 30px;
  margin: 0px 50px;
}
.font-xxl {
  font-size: 2rem;
  font-weight: 600;
}
.font-xl {
  font-size: 1.75rem;
}
.font-m {
  font-size: 1.25rem;
}
.font-s {
  font-size: 1rem;
}
.orange-text {
  color: #ed7009;
}
</style>
