<template>
  <section id="tests-section" class="vertical-5-rem-margin">
    <div class="container-fluid">
      <div class="row my-5">
        <div class="col d-flex justify-content-center">
          <span class="tests-title font-xxl"> Tests </span>
        </div>
      </div>
      <div class="row d-flex justify-content-center">
        <div class="col-10 col-md-6">
          <div
            id="smartgoals-tests"
            class="carousel slide carousel-dark"
            data-bs-ride="carousel"
          >
            <div class="carousel-indicators">
              <button
                type="button"
                data-bs-target="#smartgoals-tests"
                data-bs-slide-to="0"
                class="active"
                aria-current="true"
                aria-label="Slide 1"
              ></button>
              <button
                type="button"
                data-bs-target="#smartgoals-tests"
                data-bs-slide-to="1"
                aria-label="Slide 2"
              ></button>
              <button
                type="button"
                data-bs-target="#smartgoals-tests"
                data-bs-slide-to="2"
                aria-label="Slide 3"
              ></button>
              <button
                type="button"
                data-bs-target="#smartgoals-tests"
                data-bs-slide-to="3"
                aria-label="Slide 4"
              ></button>
              <button
                type="button"
                data-bs-target="#smartgoals-tests"
                data-bs-slide-to="4"
                aria-label="Slide 5"
              ></button>
            </div>
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img
                  src="@/assets/sections/tests/inwards-agility.png"
                  class="d-block w-100"
                  alt="Inwards Agility"
                />
                <div class="carousel-caption">
                  <h5>Inwards Agility</h5>
                </div>
              </div>
              <div class="carousel-item">
                <img
                  src="@/assets/sections/tests/the-illinois.png"
                  class="d-block w-100"
                  alt="The Illinois"
                />
                <div class="carousel-caption">
                  <h5>The Illinois</h5>
                </div>
              </div>
              <div class="carousel-item">
                <img
                  src="@/assets/sections/tests/the-505.png"
                  class="d-block w-100"
                  alt="The 505"
                />
                <div class="carousel-caption">
                  <h5>The 505</h5>
                </div>
              </div>
              <div class="carousel-item">
                <img
                  src="@/assets/sections/tests/t-test.png"
                  class="d-block w-100"
                  alt="T-test"
                />
                <div class="carousel-caption">
                  <h5>T-test</h5>
                </div>
              </div>
              <div class="carousel-item">
                <img
                  src="@/assets/sections/tests/outwards-agility.png"
                  class="d-block w-100"
                  alt="Outwards Agility"
                />
                <div class="carousel-caption">
                  <h5>Outwards Agility</h5>
                </div>
              </div>
            </div>
            <button
              class="carousel-control-prev"
              type="button"
              data-bs-target="#smartgoals-tests"
              data-bs-slide="prev"
            >
              <span class="visually-hidden">Previous</span>
            </button>
            <button
              class="carousel-control-next"
              type="button"
              data-bs-target="#smartgoals-tests"
              data-bs-slide="next"
            >
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {};
</script>
<style lang="css">
.tests-title {
  text-transform: uppercase;
  position: relative;
}
.tests-title::after {
  content: "";
  position: absolute;
  left: 0;
  top: 55px;
  width: 50%;
  border-bottom: 3px solid #ed7009;
}
.carousel-caption {
  position: relative !important;
  left: auto !important;
  right: auto !important;
  margin: 2rem;
  padding-top: 2.25rem !important;
  padding-bottom: 2.25rem !important;
}
.carousel-caption a:any-link {
  text-decoration: none !important;
  color: #fff;
  margin-top: 2rem;
}
.carousel-caption .sm-orange-btn {
  margin: 0 !important;
}
.carousel-caption h5 {
  margin-bottom: 2rem;
}
.carousel-inner img {
  object-fit: fill;
}

.carousel-indicators {
  margin-bottom: 5rem !important;
}

.details {
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  width: 11rem;
}
</style>
