<template>
  <section id="benefits-section" class="vertical-5-rem-margin">
    <div class="container">
      <div class="row">
        <div class="col">
          <span class="benefits-title font-xxl">
            Why <span class="orange-text">SmartGoals</span>
          </span>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col">
          <span class="benefits-subtitle font-s">
            Train awareness, reaction, agility and team play in dynamically
            changing game situations. Challenging and fun for all levels.
          </span>
        </div>
      </div>
      <div class="row mt-5 d-flex justify-content-center">
        <div
          class="col-12 col-sm-6 col-md-3 d-flex slide-in-from-right justify-content-center align-items-center"
        >
          <div class="icon-box-1 p-4 d-flex flex-column align-items-center">
            <div class="icon">
              <font-awesome-icon
                :icon="['fas', 'expand-arrows-alt']"
                size="4x"
              />
            </div>
            <span class="icon-title">Awareness</span>
          </div>
        </div>
        <div
          class="col-12 col-sm-6 col-md-3 d-flex justify-content-center align-items-center"
        >
          <div class="icon-box-2 p-4 d-flex flex-column align-items-center">
            <div class="icon">
              <font-awesome-icon :icon="['fas', 'lightbulb']" size="4x" />
            </div>
            <span class="icon-title">Game Intelligence</span>
          </div>
        </div>
        <div
          class="col-12 col-sm-6 col-md-3 d-flex justify-content-center align-items-center"
        >
          <div
            class="icon-box-3 slide-in-from-right p-4 d-flex flex-column align-items-center"
          >
            <div class="icon">
              <font-awesome-icon :icon="['fas', 'bolt']" size="4x" />
            </div>
            <span class="icon-title">Agility</span>
          </div>
        </div>
        <div
          class="col-12 col-sm-6 col-md-3 d-flex justify-content-center align-items-center"
        >
          <div class="icon-box-4 p-4 d-flex flex-column align-items-center">
            <div class="icon">
              <font-awesome-icon :icon="['fas', 'stopwatch-20']" size="4x" />
            </div>
            <span class="icon-title">Reaction Speed</span>
          </div>
        </div>
        <div
          class="col-12 col-sm-6 col-md-3 d-flex justify-content-center align-items-center"
        >
          <div class="icon-box-5 p-4 d-flex flex-column align-items-center">
            <div class="icon">
              <font-awesome-icon :icon="['fas', 'comments']" size="4x" />
            </div>
            <span class="icon-title">Communication</span>
          </div>
        </div>
        <div
          class="col-12 col-sm-6 col-md-3 d-flex justify-content-center align-items-center"
        >
          <div class="icon-box-6 p-4 d-flex flex-column align-items-center">
            <div class="icon">
              <font-awesome-icon :icon="['fas', 'heartbeat']" size="4x" />
            </div>
            <span class="icon-title">Stamina</span>
          </div>
        </div>
        <div
          class="col-12 col-sm-6 col-md-3 d-flex justify-content-center align-items-center"
        >
          <div class="icon-box-7 p-4 d-flex flex-column align-items-center">
            <div class="icon">
              <font-awesome-icon :icon="['fas', 'users']" size="4x" />
            </div>
            <span class="icon-title">Teamwork</span>
          </div>
        </div>
        <div
          class="col-12 col-sm-6 col-md-3 d-flex justify-content-center align-items-center"
        >
          <div class="icon-box-8 p-4 d-flex flex-column align-items-center">
            <div class="icon">
              <font-awesome-icon :icon="['fas', 'eye']" size="4x" />
            </div>
            <span class="icon-title">Vision</span>
          </div>
        </div>
      </div>

      <div class="video-container">
        <div class="row d-flex align-items-center justify-content-start">
          <div class="col-12 col-md-6 order-2 order-md-1 mt-5 mt-md-0">
            <div class="ratio ratio-16x9">
              <iframe
                src="https://www.youtube.com/embed/Wf9Qm6jacMI"
                title="YouTube video"
                allowfullscreen
              ></iframe>
            </div>
          </div>
          <div
            class="col-12 col-md-6 d-flex align-items-center ps-md-5 mt-5 mt-md-0 pe-0 pe-md-5 order-1 order-md-2"
          >
            <div class="d-flex flex-column">
              <span class="video-container__title font-xl">
                Interactive Training Method
              </span>
              <span class="font-s"
                >SmartGoals is an interactive training method that works with
                light signals. The lit up SmartGoals are the targets. Run,
                dribble, or shoot a ball through a lit up SmartGoal and the
                light will jump to another SmartGoal. Train awareness, reaction,
                agility and team play in dynamically changing game situations.
                Challenging and fun for all levels.</span
              >
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6 d-flex align-items-center mt-5">
            <div class="d-flex flex-column">
              <span class="video-container__title font-xl"
                >Measure it, to manage it.</span
              >
              <span class="font-s"
                >Data collection and managing data becomes more important in
                many sports. The SmartGoals keep track of how fast you are and
                how many scores are made. Fun as a competitive element during
                training sessions; who gets the highest score? But especially
                valuable as a performance system to do sprint tests, agility
                tests, passing accuracy tests, etc. Compare the results with
                yourself and with others and improve every day.</span
              >
            </div>
          </div>
          <div class="col-12 col-md-6 d-flex mt-5">
            <div class="ratio ratio-16x9">
              <iframe
                src="https://www.youtube.com/embed/LmEAYubFJhc"
                title="YouTube video"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {};
</script>
<style lang="css">
.benefits-title {
  text-transform: uppercase;
  position: relative;
}
.benefits-title::after {
  content: "";
  position: absolute;
  left: 0;
  top: 55px;
  width: 50%;
  border-bottom: 3px solid #ed7009;
}
.benefits-subtitle,
.icon-title {
  color: #777777;
}
.icon-title {
  margin-top: 1rem;
}
.icon {
  color: #ed7009;
}
.icon-box-1,
.icon-box-2,
.icon-box-3,
.icon-box-4,
.icon-box-5,
.icon-box-6,
.icon-box-7,
.icon-box-8 {
  opacity: 0;
  transition: all ease-in 1000ms;
}
.icon-box-1-anim,
.icon-box-2-anim,
.icon-box-3-anim,
.icon-box-4-anim,
.icon-box-5-anim,
.icon-box-6-anim,
.icon-box-7-anim,
.icon-box-8-anim {
  opacity: 100;
}
.icon-box-2-anim {
  transition-delay: 500ms;
}
.icon-box-3-anim {
  transition-delay: 1000ms;
}
.icon-box-4-anim {
  transition-delay: 1500ms;
}
.icon-box-5-anim {
  transition-delay: 1750ms;
}
.icon-box-6-anim {
  transition-delay: 2000ms;
}
.icon-box-7-anim {
  transition-delay: 2250ms;
}
.icon-box-8-anim {
  transition-delay: 2500ms;
}
.video-container {
  margin-top: 5rem;
  text-align: left;
}
.video-container__title {
  text-transform: uppercase;
  margin-bottom: 2rem;
}
</style>
