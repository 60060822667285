<template>
  <div class="wrapper">
    <Header
      id="welcome-section"
      :visitFromBlogPage="true"
      style="position:fixed; width: 100%"
    />
    <div class="container">
      <div
        v-if="isLoading"
        class="d-flex justify-content-center align-items-center vh-100"
      >
        <div
          class="spinner-border d-flex"
          style="width: 5rem; height: 5rem;"
          role="status"
        >
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <div v-else>
        <transition appear>
          <div class="row row-cols-1 row-cols-md-2 g-4 my-5">
            <div v-for="post of listOfPosts" :key="post.id" class="col">
              <div class="card mt-3 shadow-sm">
                <router-link :to="`/blog/posts/${post.url}`">
                  <div class="card-header">
                    <div
                      class="card-img-container"
                      style="entry-img"
                      :style="{
                        backgroundImage:
                          post.image == 'no-picture'
                            ? 'url(' + defaultImg + ')'
                            : 'url(' + post.image + ')',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center'
                      }"
                    ></div>
                    <div class="pt-3">
                      <a
                        class="category-link"
                        :href="'/blog/categories/' + post.category"
                        >{{ post.category }}</a
                      >
                    </div>
                  </div>
                  <div class="card-body d-flex align-items-start">
                    <h5 class="card-title">
                      {{ post.title }}
                    </h5>
                  </div>
                </router-link>
                <div class="card-footer d-flex align-items-center">
                  <div class="d-flex">
                    <img
                      :src="post.author.avatar"
                      class="author-avatar"
                      alt="Author avatar"
                    />
                  </div>
                  <div class="d-flex px-2">
                    <a :href="post.author.social" target="_blank"
                      ><span>{{ post.author.name }}</span></a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
    <!-- <Footer /> -->
  </div>
</template>

<script>
import Header from "@/components/Header";
//import Footer from "@/components/Footer";

// eslint-disable-next-line no-unused-vars
import defaultImage from "@/assets/default-blog-img.svg";

export default {
  components: {
    Header,
    //Footer
  },
  data() {
    return {
      loading: true,
      posts: null,
      defaultImg: null
    };
  },
  async mounted() {
    await this.$store.dispatch("listAllEntries");
    this.loading = false;
    this.defaultImg = defaultImage;
  },
  computed: {
    listOfPosts() {
      return this.$store.getters.listAllEntries;
    },
    capitalizeCategory(lowerCasePostCategory) {
      return (
        lowerCasePostCategory.charAt(0).toUpperCase() +
        lowerCasePostCategory.slice(1)
      );
    },
    isLoading() {
      return this.loading;
    },
    checkImage(img) {
      if (img.image === "no-picture") {
        return `url(${this.defaultImg})`;
      } else {
        return `url(${img.image})`;
      }
    }
  }
};
</script>

<style scoped>
.wrapper {
  overflow: hidden;
}
.card-header {
  background-color: white;
  border: 0px;
}
a:any-link {
  color: #ed7009;
  text-decoration: none;
}
@media (max-width: 992px) {
  #welcome-section {
    box-shadow: 0px 2px 25px rgb(0, 0, 0, 0.05);
  }
}
.v-enter-active,
.v-leave-active {
  transition: opacity 2s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
.author-avatar {
  border-radius: 50%;
  width: 3rem;
}
.card {
  border-radius: 8px;
}
.card-body {
  min-height: 7.5rem;
}
.card-img-container {
  height: 13rem;
  border-radius: 8px;
}
.category-link {
  padding: 0.75rem;
  background: linear-gradient(to right, #131132, #131132);
  background-repeat: no-repeat;
  background-size: 0 100%;
  transition: background-size 0.5s 0s;
  font-weight: bold;
  border-radius: 8px;
}
.category-link:hover {
  background-size: 100% 100%;
}
.card-title {
  color: #131132;
  overflow: hidden;
}
</style>