<template>
  <section id="usecases-section" class="vertical-5-rem-margin">
    <div class="container">
      <div class="row mb-5">
        <div class="col">
          <span class="usecases-title font-xxl"> SmartGoals </span>
        </div>
      </div>
      <div class="row d-flex justify-content-center mt-5">
        <div class="col-12 col-md-8 d-flex text-center">
          <span class="font-s">
            Learn more about use cases, watch training videos, read testimonials
            from high profile sports innovators, and check our references
            including grassroots clubs and Champions League winning teams.
          </span>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col">
          <div class="mocups">
            <img
              src="@/assets/sections/sm-macbook-pro.png"
              class="img-fluid"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
    <div class="">
      <div class="iphone-1">
        <img
          src="@/assets/sections/mockups/iphone-mockup-1.png"
          class="img-fluid"
          alt=""
        />
      </div>
      <div class="iphone-2">
        <img
          src="@/assets/sections/mockups/iphone-mockup-2.png"
          class="img-fluid"
          alt=""
        />
      </div>
      <div class="iphone-3">
        <img
          src="@/assets/sections/mockups/iphone-mockup-3.png"
          class="img-fluid"
          alt=""
        />
      </div>
    </div>
  </section>
</template>
<script>
export default {};
</script>
<style lang="css">
#usecases-section {
  position: relative;
  z-index: 50;
}
.usecases-title {
  position: relative;
  text-transform: uppercase;
}
.usecases-title::after {
  content: "";
  position: absolute;
  left: 0;
  top: 55px;
  width: 50%;
  border-bottom: 3px solid #f2994a;
}
.mockups {
  position: absolute;
  width: 100%;
  min-height: 100%;
  opacity: 40%;
}
.iphone-1 {
  position: absolute;
  left: 1rem;
  bottom: 1rem;
  opacity: 0%;
  transition: all ease-in-out 1000ms;
}
.iphone-2 {
  position: absolute;
  left: 12rem;
  bottom: 10rem;
  opacity: 0;
  transition: all ease-in-out 1000ms;
}
.iphone-3 {
  position: absolute;
  left: 23rem;
  bottom: 1rem;
  opacity: 0;
  transition: all ease-in-out 1000ms;
}
.iphone-1 img,
.iphone-2 img,
.iphone-3 img {
  height: 20rem;
}
@media (max-width: 768px) {
  .iphone-1 img,
  .iphone-2 img,
  .iphone-3 img {
    height: 10rem;
  }
  .iphone-1 {
    position: absolute;
    left: 1rem;
    bottom: 1rem;
    opacity: 100%;
    transition: all ease-in-out 1000ms;
  }
  .iphone-2 {
    position: absolute;
    left: 7rem;
    bottom: 5rem;
    opacity: 0;
    transition: all ease-in-out 1000ms;
  }
  .iphone-3 {
    position: absolute;
    left: 13rem;
    bottom: 1rem;
    opacity: 0;
    transition: all ease-in-out 1000ms;
  }
}
@media (max-width: 992px) and (min-width: 768px) {
  .iphone-1 img,
  .iphone-2 img,
  .iphone-3 img {
    height: 15rem;
  }
  .iphone-1 {
    position: absolute;
    left: 1rem;
    bottom: 1rem;
    opacity: 100;
    transition: all ease-in-out 1000ms;
  }
  .iphone-2 {
    position: absolute;
    left: 10rem;
    bottom: 5rem;
    opacity: 0;
    transition: all ease-in-out 1000ms;
  }
  .iphone-3 {
    position: absolute;
    left: 19rem;
    bottom: 1rem;
    opacity: 0;
    transition: all ease-in-out 1000ms;
  }
}
</style>
