import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state() {
    return {
      listAllEntries: [],
      listByCategory: [],
      listByLabel: [],
      listSingleEntry: null,
      listSingleEntryByUrl: null,
    };
  },
  getters: {
    listAllEntries(state) {
      return state.listAllEntries;
    },
    listByCategory(state) {
      return state.listByCategory;
    },
    listByLabel(state) {
      return state.listByLabel;
    },
    listSingleEntry(state) {
      return state.listSingleEntry;
    },
    listSingleEntryByUrl(state) {
      return state.listSingleEntryByUrl;
    },
  },
  mutations: {
    listAllEntries(state, payload) {
      state.listAllEntries = payload.reverse();
    },
    listByCategory(state, payload) {
      state.listByCategory = payload.reverse();
    },
    listByLabel(state, payload) {
      state.listByLabel = payload;
    },
    listSingleEntry(state, payload) {
      state.listSingleEntry = payload;
    },
    listSingleEntryByUrl(state, payload) {
      state.listSingleEntryByUrl = payload;
    },
  },
  actions: {
    async listAllEntries(context) {
      try {
        return fetch(`${process.env.VUE_APP_BACKEND_URL}/api/blog/entries`)
          .then((response) => response.json())
          .then((data) => {
            context.commit("listAllEntries", data);
          });
      } catch (err) {
        throw new Error(err);
      }
    },
    async listByCategory(context, categoryName) {
      try {
        fetch(
          `${process.env.VUE_APP_BACKEND_URL}/api/blog/categories/${categoryName}`
        )
          .then((response) => response.json())
          .then((data) => {
            context.commit("listByCategory", data);
          });
      } catch (err) {
        throw new Error(err);
      }
    },
    async listByLabel(context, labelName) {
      try {
        fetch(`${process.env.VUE_APP_BACKEND_URL}/api/blog/labels/${labelName}`)
          .then((response) => response.json())
          .then((data) => {
            context.commit("listByLabel", data);
          });
      } catch (err) {
        throw new Error(err);
      }
    },
    async listSingleEntry(context, entryId) {
      try {
        return fetch(
          `${process.env.VUE_APP_BACKEND_URL}/api/blog/entries/${entryId}`
        )
          .then((response) => response.json())
          .then((data) => {
            context.commit("listSingleEntry", data);
          });
      } catch (err) {
        throw new Error(err);
      }
    },
    async listSingleEntryByUrl(context, entryUrl) {
      try {
        return fetch(
          `${process.env.VUE_APP_BACKEND_URL}/api/blog/posts/${entryUrl}`
        )
          .then((response) => response.json())
          .then((data) => {
            context.commit("listSingleEntryByUrl", data);
          });
      } catch (err) {
        throw new Error(err);
      }
    },
  },
});
