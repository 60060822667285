<template>
  <div>
    <Faq />
  </div>
</template>

<script>
import Faq from "../components/sections/Faq.vue";
export default {
  metaInfo: {
    title: "SmartGoals - FAQ",
    titleTemplate: null,
  },
  components: {
    Faq,
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>
