<template>
  <section id="contact-section" class="vertical-5-rem-margin">
    <div class="container">
      <div class="row my-5">
        <div class="col d-flex justify-content-center">
          <span class="extra-title font-xxl" style="margin-top: 80px">
            Contact
          </span>
        </div>
      </div>
      <div class="row">
        <div class="col my-4">
          <h3>{{ contactFormInfoText }}</h3>
        </div>
      </div>
      <div class="row d-flex justify-content-center">
        <div class="col">
          <div class="container contact-section">
            <form @submit="checkForm" class="form row">
              <div class="form-group col-lg-6 mb-4">
                <label class="mb-2" for="firstname">First name</label>
                <input
                  type="text"
                  class="form-control"
                  id="firstname"
                  aria-describedby="firstname"
                  v-model="firstname"
                  required
                />
                <div class="invalid-feedback">Please choose a username.</div>
              </div>
              <div class="form-group col-lg-6 mb-4">
                <label class="mb-2" for="lastname">Last name</label>
                <input
                  type="text"
                  class="form-control"
                  id="lastname"
                  aria-describedby="lastname"
                  v-model="lastname"
                  required
                />
              </div>
              <div class="form-group col-lg-6 mb-4">
                <label class="mb-2" for="email">Email</label>
                <input
                  type="email"
                  class="form-control"
                  id="email"
                  aria-describedby="email"
                  v-model="email"
                  required
                />
              </div>
              <div class="form-group col-lg-6 mb-4">
                <label class="mb-2" for="phonenumber">Phone number</label>
                <input
                  type="numeric"
                  class="form-control"
                  id="phonenumber"
                  aria-describedby="phonenumber"
                  v-model="phonenumber"
                  required
                />
              </div>
              <div class="form-group col-lg-6 mb-4">
                <label class="mb-2" for="club">Organization</label>
                <input
                  type="text"
                  class="form-control"
                  id="club"
                  aria-describedby="club"
                  v-model="club"
                />
              </div>
              <div class="form-group col-lg-6 mb-4">
                <label class="mb-2" for="country">Country</label>
                <input
                  type="text"
                  class="form-control"
                  id="country"
                  aria-describedby="country"
                  v-model="country"
                />
              </div>
               <div class="form-group col-lg-12 mb-4">
                 <label class="mb-2" for="product">Related product</label>
                  <select
                    id="product"
                    class="form-select"
                    aria-label="Product"
                    v-model="product"
                  >
                    <option disabled="" selected="true"
                      >Please select the related product</option
                    >
                    <option value="SmartGoals">SmartGoals</option>
                    <option value="SkillGames">SkillGames</option>
                    <option value="SkillCourts">SkillCourts</option>
                  </select>
              </div>
              <div class="form-check col-lg-12 mb-4">
                <label class="mb-2" for="details">Details</label>
                <textarea
                  class="form-control"
                  id="details"
                  rows="5"
                  v-model="details"
                  required
                ></textarea>
              </div>

              <div class="col-lg-12">
                <button type="submit" class="btn">Send</button>
              </div>
              <div v-if="sent" class="row justify-content-center">
                <div class="col-lg-12 email-sent" style="height: 250px">
                  <svg width="150" height="150" class="pt-4">
                    <path id="check" d="M10,50 l25,40 l95,-70" />
                  </svg>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      firstname: null,
      lastname: null,
      email: "",
      phonenumber: null,
      club: null,
      country: null,
      product: null,
      details: null,
      sent: false,
    };
  },
  props: {
    contactFormInfoText: String
  },
  components: {},
  methods: {
    checkForm(e) {
      e.preventDefault();
      this.sent = true;
      const data = {
        first_name: this.firstname,
        last_name: this.lastname,
        email: this.email,
        message: `
        Phone: ${this.phonenumber},
        Team: ${this.club},
        Country: ${this.country},
        Related product: ${this.product},
        Details: ${this.details}
        `,
      };

      axios({
        method: "post",
        url: "https://skillcourts-api.skillgam.es/api/contact",
        data: data,
        headers: { "Content-Type": "application/json" },
      })
        .then((resp) => {
          console.log(resp);
        })
        .catch((err) => {
          console.log(err);
        });

      setTimeout(() => {
        this.setDefaultForm();
      }, 3000);
    },
    setDefaultForm() {
      (this.first_name = ""),
        (this.last_name = ""),
        (this.email = ""),
        (this.phonenumber = ""),
        (this.club = ""),
        (this.country = ""),
        (this.product = ""),
        (this.details = ""),
        (this.sent = false);
    },
  },
};
</script>
<style lang="css" scoped>
#contact-section {
  min-height: 25vh;
  z-index: 50;
  position: relative;
}
.contact-section {
  color: #000;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.heading h4 {
  font-size: 3rem;
  margin-bottom: 5rem;
}

form {
  color: #000;
  width: 50%;
  text-align: left;
  background-color: #fff;
  border-radius: 1rem;
  padding: 2rem;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.4);
}

.form-check {
  padding-left: calc(var(--bs-gutter-x) / 2);
}

form button {
  color: #fff;
  width: 100%;
  background-color: #ed7009;
  border-radius: 40px;
  height: 44px;
  border: none;
  padding: 10px 30px;
}

form button:focus,
form button:hover {
  color: #fff;
  background-color: #ed7009;
}

form input,
form textarea,
form select {
  background-color: #f9fcff;
  border: 1px solid #e4e4e4;
}

form input:focus,
form textarea:focus,
form select:focus {
  border: 1px solid #ed7009;
  box-shadow: none;
}

textarea {
  resize: none;
}

.email-sent {
  height: 20rem;
  width: 20rem;
  background-color: transparent;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#check {
  fill: none;
  stroke: #ed7009;
  stroke-width: 20;
  stroke-linecap: round;
  stroke-dasharray: 180;
  stroke-dashoffset: 180;
  animation: draw 2s infinite ease;
}

@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}

@media (max-width: 1199.98px) {
  form {
    width: 100%;
  }
}
</style>
