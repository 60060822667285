<template>
  <div class="right-side-menu d-none d-md-block me-5">
    <div class="right-border">
      <scrollactive
        active-class="active"
        :offset="80"
        v-on:itemchanged="onItemChanged"
      >
        <ul>
          <li>
            <a href="#home-section" class="scrollactive-item home">Home</a>
          </li>
          <li>
            <a href="#concept-section" class="scrollactive-item concept"
              >Concept</a
            >
          </li>
          <li>
            <a href="#specs-section" class="scrollactive-item specs">Specs</a>
          </li>
          <li>
            <a href="#specs-head" class="scrollactive-item specs-head"
              >Specs II</a
            >
          </li>
          <li>
            <a href="#usecases-section" class="scrollactive-item usecases"
              >Use cases</a
            >
          </li>
          <li>
            <a href="#benefits-section" class="scrollactive-item benefits"
              >Benefits</a
            >
          </li>
          <li>
            <a
              href="#testimonials-section"
              class="scrollactive-item testimonials"
              >Our clients</a
            >
          </li>
          <li>
            <a href="#offers-section" class="scrollactive-item offers"
              >Offers</a
            >
          </li>
          <li>
            <a href="#extra-section" class="scrollactive-item">Extra</a>
          </li>
        </ul>
      </scrollactive>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currItem: undefined,
    };
  },
  methods: {
    onItemChanged(event, currentItem) {
      this.currItem = currentItem;

      if (currentItem._prevClass == "scrollactive-item home") {
        if (
          document
            .querySelector(".sm-tower-img")
            .classList.contains("scale-down-with-anim")
        ) {
          document
            .querySelector(".sm-tower-img")
            .classList.remove("scale-down-with-anim");
        }
        this.onHideProductBox();
      }
      if (currentItem._prevClass == "scrollactive-item concept") {
        document
          .querySelector(".sm-tower-img")
          .classList.add("scale-down-with-anim");
        this.onRemoveSpecsPosition();
        this.onChangeBackground();
        this.onShowHideSmartGoalsDetails();
        this.onHideDetailsBox();
        this.onShowProductBoxInConcept();
      }
      if (currentItem._prevClass == "scrollactive-item specs") {
        document
          .querySelector(".sm-tower-img")
          .classList.add("right-position-with-anim");
        this.onChangeBackground();
        this.onShowHideSmartGoalsDetails();
        this.onShowDetailsBox();
        this.onFlashBackground();
        this.onHideProductBox();
      }
      if (currentItem._prevClass == "scrollactive-item specs-head") {
        this.onFlashBackground();
        this.onRemoveBenefitBox();
        this.onHideIphoneMockups();
      }
      if (currentItem._prevClass == "scrollactive-item usecases") {
        this.onRemoveBenefitBox();
        this.onShowIphoneMockups();
      }
      if (currentItem._prevClass == "scrollactive-item benefits") {
        this.onShowBenefitBox();
        this.onHideIphoneMockups();
      }
    },
    onChangeBackground() {
      if (this.currItem._prevClass == "scrollactive-item specs") {
        document.querySelector(".main-wrapper").style.backgroundColor =
          "#19191B";
      } else {
        document.querySelector(".main-wrapper").style.backgroundColor =
          "#FCFCFC";
      }
    },
    onFlashBackground() {
      if (this.currItem._prevClass == "scrollactive-item specs-head") {
        document.querySelector(".sm-head__img").style.opacity = "100";
        document.querySelector(".sm-head-texts").style.opacity = "100";
      } else {
        document.querySelector(".sm-head__img").style.opacity = "0";
        document.querySelector(".sm-head-texts").style.opacity = "0";
      }
    },
    onShowHideSmartGoalsDetails() {
      if (this.currItem._prevClass == "scrollactive-item specs") {
        document.querySelector(".sm-details").style.opacity = "1";
      } else {
        document.querySelector(".sm-details").style.opacity = "0";
      }
    },
    onRemoveSpecsPosition() {
      if (
        document
          .querySelector(".sm-tower-img")
          .classList.contains("right-position-with-anim")
      ) {
        document
          .querySelector(".sm-tower-img")
          .classList.remove("right-position-with-anim");
      }
    },
    onShowDetailsBox() {
      document.querySelector(".box-1").classList.add("details-box-fade-in-1");
      document.querySelector(".box-2").classList.add("details-box-fade-in-2");
      document.querySelector(".box-3").classList.add("details-box-fade-in-3");
      document.querySelector(".box-4").classList.add("details-box-fade-in-4");
      document.querySelector(".box-5").classList.add("details-box-fade-in-5");
    },
    onHideDetailsBox() {
      document
        .querySelector(".box-1")
        .classList.remove("details-box-fade-in-1");
      document
        .querySelector(".box-2")
        .classList.remove("details-box-fade-in-2");
      document
        .querySelector(".box-3")
        .classList.remove("details-box-fade-in-3");
      document
        .querySelector(".box-4")
        .classList.remove("details-box-fade-in-4");
      document
        .querySelector(".box-5")
        .classList.remove("details-box-fade-in-5");
    },
    onShowBenefitBox() {
      document.querySelector(".icon-box-1").classList.add("icon-box-1-anim");
      document.querySelector(".icon-box-2").classList.add("icon-box-2-anim");
      document.querySelector(".icon-box-3").classList.add("icon-box-3-anim");
      document.querySelector(".icon-box-4").classList.add("icon-box-4-anim");
      document.querySelector(".icon-box-5").classList.add("icon-box-5-anim");
      document.querySelector(".icon-box-6").classList.add("icon-box-6-anim");
      document.querySelector(".icon-box-7").classList.add("icon-box-7-anim");
      document.querySelector(".icon-box-8").classList.add("icon-box-8-anim");
    },
    onRemoveBenefitBox() {
      document.querySelector(".icon-box-1").classList.remove("icon-box-1-anim");
      document.querySelector(".icon-box-2").classList.remove("icon-box-2-anim");
      document.querySelector(".icon-box-3").classList.remove("icon-box-3-anim");
      document.querySelector(".icon-box-4").classList.remove("icon-box-4-anim");
      document.querySelector(".icon-box-5").classList.remove("icon-box-5-anim");
      document.querySelector(".icon-box-6").classList.remove("icon-box-6-anim");
      document.querySelector(".icon-box-7").classList.remove("icon-box-7-anim");
      document.querySelector(".icon-box-8").classList.remove("icon-box-8-anim");
    },
    onShowProductBoxInConcept() {
      document.querySelector(".product-box").style.opacity = "100";
    },
    onHideProductBox() {
      document.querySelector(".product-box").style.opacity = "0";
    },
    onShowIphoneMockups() {
      document.querySelector(".iphone-1").style.opacity = "100";
      document.querySelector(".iphone-2").style.opacity = "100";
      document.querySelector(".iphone-3").style.opacity = "100";
    },
    onHideIphoneMockups() {
      document.querySelector(".iphone-1").style.opacity = "0";
      document.querySelector(".iphone-2").style.opacity = "0";
      document.querySelector(".iphone-3").style.opacity = "0";
    },
  },
};
</script>

<style lang="css" scoped>
.right-side-menu {
  display: inline-block;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  z-index: 50 !important;
  position: fixed;
}
.right-side-menu ul li {
  font-size: 1rem;
  font-weight: 500;
  text-align: right;
  list-style-type: none;
}
.right-side-menu a:any-link {
  color: #d4d4d4;
  text-decoration: none;
}
li .active {
  color: #ed7009 !important;
}
.right-side-menu ul li {
  padding-top: 1.5rem;
}
.right-side-menu ul li:first-of-type {
  padding-top: 0rem !important;
}
.right-border {
  padding-right: 0.8rem;
  border-right: 4px solid #d4d4d4;
}
</style>
