<template>
  <div class="wrapper" style="height: 100%">
    <Header
      id="welcome-section"
      :visitFromBlogPage="true"
      style="position:fixed; width: 100%"
    />
    <div
      v-if="isLoading"
      class="d-flex justify-content-center align-items-center vh-100"
    >
      <div
        class="spinner-border d-flex"
        style="width: 5rem; height: 5rem;"
        role="status"
      >
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div v-else>
      <div class="container">
        <div class="row my-5">
          <div
            id="sidebar-holder"
            class="col-lg-1 col-md-2 col-sm-12 order-last order-md-first order-lg-first d-none d-sm-none d-md-flex justify-content-center"
          >
            <div id="sidebar">
              <div id="sidebar-container2">
                <router-link to="/blog/posts">
                  <div
                    id="sidebar-content"
                    class="d-flex flex-column align-items-center justify-content-center"
                  >
                    <img
                      src="../assets/arrow-left.svg"
                      class="img-fluid"
                      alt="Vissza az előző oldalra"
                    />
                    <h5>Vissza</h5>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
          <div
            class="col-lg-11 col-md-10 col-sm-12 order-sm-first order-md-first order-lg-first"
          >
            <div class="row mt-5">
              <div class="col">
                <span class="h2">{{ showEntry.title }}</span>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6 d-flex align-items-center">
                <div id="author-avatar-small">
                  <img
                    :src="showEntry.author.avatar"
                    class="card-img-top"
                    alt="..."
                  />
                </div>
                <div class="p-2">
                  <a :href="showEntry.author.social" target="_blank"
                    ><span>{{ showEntry.author.name }}</span></a
                  >
                </div>
              </div>
              <div class="col-sm-12 col-md-6 d-flex flex-row">
                <div class="mt-2">{{ formatDate }}</div>
                <div class="mt-2 ps-2">|</div>
                <div class="mt-2 ps-2">
                  <a
                    class="category-link"
                    :href="formatCategoryUrl"
                    target="_blank"
                    >{{ showEntry.category }}</a
                  >
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 col-md-6">
                <div class="mt-2">
                  Average reading time.
                  {{ Math.round(showStatistics.minutes) }} minutes.
                </div>
              </div>
              <div class="col-sm-12 col-md-6 mt-2">
                <ShareNetwork
                  class="pe-2"
                  network="facebook"
                  :url="formatSocialURL"
                  :title="showEntry.title"
                  :quote="showEntry.title"
                >
                  <font-awesome-icon
                    :icon="{ prefix: 'fab', iconName: 'facebook-square' }"
                    size="lg"
                  />
                </ShareNetwork>

                <ShareNetwork
                  class="pe-2"
                  network="twitter"
                  :title="showEntry.title"
                  :url="formatSocialURL"
                >
                  <font-awesome-icon
                    :icon="{ prefix: 'fab', iconName: 'twitter-square' }"
                    size="lg"
                  />
                </ShareNetwork>
                <ShareNetwork
                  class="pe-2"
                  network="linkedin"
                  :title="showEntry.title"
                  :url="formatSocialURL"
                >
                  <font-awesome-icon
                    :icon="{ prefix: 'fab', iconName: 'linkedin' }"
                    size="lg"
                  />
                </ShareNetwork>
                <ShareNetwork
                  class="pe-2"
                  network="reddit"
                  :title="showEntry.title"
                  :url="formatSocialURL"
                >
                  <font-awesome-icon
                    :icon="{ prefix: 'fab', iconName: 'reddit-square' }"
                    size="lg"
                  />
                </ShareNetwork>
                <ShareNetwork
                  class=""
                  network="email"
                  :url="formatSocialURL"
                  :title="showEntry.title"
                >
                  <font-awesome-icon
                    :icon="{ prefix: 'fas', iconName: 'envelope-square' }"
                    size="lg"
                  />
                </ShareNetwork>
              </div>
            </div>
            <!-- This row holds the main img for the blog body -->
            <div class="row mt-4">
              <img :src="checkEntryImg" class="card-img-top" alt="..." />
            </div>
            <!-- This row contains the actual Sportlander blog body -->
            <div class="row mt-4">
              <div id="blog-body" class="col-12">
                <div class="a" v-html="showEntry.content"></div>
              </div>
            </div>
            <!-- Footer for blog body -->
            <div class="row my-4">
              <div class="col">
                <div class="text-center">
                  Did you like our content? Explore more on
                  <a :href="`/blog/categories/${showEntry.category}`">
                    <span class="badge rounded-pill bg-secondary">{{
                      showEntry.category
                    }}</span>
                  </a>
                  or
                  <span v-for="label of showEntry.labels" :key="label">
                    <a :href="`/blog/labels/${label.trim()}`">
                      <span class="badge rounded-pill bg-secondary my-1">{{
                        label
                      }}</span> </a
                    >&nbsp;
                  </span>
                  as well.
                </div>
              </div>
            </div>
            <div class="row d-flex">
              <div
                class="col d-flex align-items-center justify-content-center flex-row"
              ></div>
            </div>
            <!-- This row contains the about the author section -->
            <div
              class="row my-5 d-flex align-items-center justify-content-center justify-content-md-start"
            >
              <div
                id="author-avatar"
                class="col-sm-12 col-md-4 col-lg-3 d-flex"
              >
                <img
                  :src="showEntry.author.avatar"
                  class="card-img-top"
                  alt="..."
                />
              </div>
              <div
                class="col-sm-12 col-md-8 col-lg-9 d-flex align-items-center justify-content-center justify-content-md-start"
              >
                <div
                  class="d-flex flex-column align-items-center align-items-md-start mt-3 mt-md-0"
                >
                  <div>
                    <a :href="showEntry.author.social" target="_blank"
                      ><span class="h4 py-2">{{
                        showEntry.author.name
                      }}</span></a
                    >
                  </div>
                  <div>
                    <p class="lead text-center">
                      {{ showEntry.author.description }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";

import defaultImage from "../assets/default-blog-img.svg";

// import headful from 'headful'

export default {
  setup() {},
  name: "Post",
  components: {
    Header,
    Footer
  },
  data() {
    return {
      loading: true,
      entryUrl: null,
      post: null
    };
  },
  async beforeMount() {
    this.entryUrl = this.$route.params.entryUrl;
    await this.getSingleEntry();
    await this.makeImagesResponsive();
    await this.makeVideoFullWidth();
  },
  methods: {
    async getSingleEntry() {
      await this.$store.dispatch("listSingleEntryByUrl", this.entryUrl);
      this.post = await this.$store.getters.listSingleEntryByUrl;
      document.title = `Sportlander Blog - ${this.post.entry.title}`;
      this.loading = false;
    },
    makeImagesResponsive() {
      const imgs = document.getElementsByTagName("img");
      for (let i = 0; i < imgs.length; i++) {
        if (imgs[i].classList.length === 0) {
          imgs[i].classList.add("img-fluid");
        }
      }
    },
    makeVideoFullWidth() {
      const videos = document.getElementsByTagName("iframe");
      videos[0].setAttribute("height", "300px");
      videos[0].setAttribute("width", "100%");
    }
  },
  computed: {
    showEntry() {
      return this.post.entry;
    },
    showStatistics() {
      return this.post.readingStatistics;
    },
    isLoading() {
      return this.loading;
    },
    formatDate() {
      const date = new Date(this.showEntry.updatedAt);
      return date.toLocaleDateString("hu-HU");
    },
    formatSocialURL() {
      return `${process.env.VUE_APP_FRONTEND_URL}/blog/posts/${this.showEntry.url}`;
    },
    formatCategoryUrl() {
      return `${process.env.VUE_APP_FRONTEND_URL}/blog/categories/${this.showEntry.category}`;
    },
    checkEntryImg() {
      if (this.showEntry.image != "no-picture") {
        return this.showEntry.image;
      } else {
        return defaultImage;
      }
    }
  }
};
</script>
<style scoped>
.a {
  text-align: center;
}
.a :not(.ql-align-center) {
  text-align: justify;
}
.a >>> blockquote {
  font-style: italic;
  border-left: 5px solid #ed7009;
}
.a >>> blockquote {
  padding-left: 3rem;
  padding-top: 10px;
  padding-bottom: 10px;
  background-image: url("../assets/quote-1.svg");
  opacity: 0.8;
  background-repeat: no-repeat;
  background-size: 2rem;
  background-position: left 0.5rem top;
}
.a >>> a {
  color: #ed7009 !important;
  font-weight: 600;
  text-decoration: none;
}
#sidebar {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  top: 25%;
  position: fixed;
}
#sidebar-holder {
  display: flex;
}
#author-avatar {
  width: 10rem;
}
#author-avatar-small {
  width: 3rem;
}
#author-avatar-small > img {
  border-radius: 50%;
}
#author-avatar > img {
  border-radius: 50%;
}
#sidebar-content > img {
  width: 2.5rem;
}
a:any-link {
  color: #ed7009;
  text-decoration: none;
}
.category-link {
  padding: 0.75rem;
  background: linear-gradient(to right, #131132, #131132);
  background-repeat: no-repeat;
  background-size: 0 100%;
  transition: background-size 0.5s 0s;
  font-weight: bold;
  border-radius: 8px;
}
.category-link:hover {
  background-size: 100% 100%;
}
</style>