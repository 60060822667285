<template>
  <div class="container">
    <section class="smartgoals-tower">
      <div class="row">
        <div class="arrows sm-details">
          <div class="details-box box-1 my-2">
            <span>Water Resistant</span>
            <img
              src="@/assets/sections/sm-product-details-arrows/arrow-01.png"
              alt=""
            />
          </div>
          <div class="details-box box-2 delay-2 my-2">
            <span>LED Visible in Daylights</span>
            <img
              src="@/assets/sections/sm-product-details-arrows/arrow-01.png"
              alt=""
            />
          </div>
          <div class="details-box box-3 my-2">
            <span>8 hours of training</span>
            <img
              src="@/assets/sections/sm-product-details-arrows/arrow-01.png"
              alt=""
            />
          </div>
          <div class="details-box box-4 my-2">
            <span>Shock Proof (Ball impacts)</span>
            <img
              src="@/assets/sections/sm-product-details-arrows/arrow-01.png"
              alt=""
            />
          </div>
          <div class="details-box box-5 my-2">
            <span>Counting Scores</span>
            <img
              src="@/assets/sections/sm-product-details-arrows/arrow-01.png"
              alt=""
            />
          </div>
        </div>
        <div class="col-12 sm-tower-container d-flex justify-content-center">
          <img
            src="@/assets/sections/SmartCone-Oranje.png"
            class="img-fluid sm-tower-img d-flex default-position"
            alt="SmartGoals"
          />
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {};
</script>
<style lang="css" scoped>
.smartgoals-tower {
  z-index: 50;
  position: relative;
}
.sm-details {
  opacity: 0;
  transition: all ease-in-out 500ms;
  width: 50vh;
  overflow: hidden;
  color: #d4d4d4;
}
.details-box {
  transition: all ease-in-out;
  display: flex;
  flex-direction: column;
  padding: 0.2rem;
  opacity: 0;
}
.details-box-fade-in-1 {
  opacity: 1;
  transition-delay: 1000ms;
  transition-duration: 1000ms;
}
.details-box-fade-in-2 {
  opacity: 1;
  transition-delay: 1500ms;
  transition-duration: 1000ms;
}
.details-box-fade-in-3 {
  opacity: 1;
  transition-delay: 2000ms;
  transition-duration: 1000ms;
}
.details-box-fade-in-4 {
  opacity: 1;
  transition-delay: 2500ms;
  transition-duration: 1000ms;
}
.details-box-fade-in-5 {
  opacity: 1;
  transition-delay: 3000ms;
  transition-duration: 1000ms;
}
.arrows {
  position: absolute;
}
.sm-tower-container {
  overflow: hidden;
}
.sm-details-with-arrow {
  z-index: 51;
}
.sm-tower-container img {
}
.sm-tower-img {
  transition: all 3000ms;
}
.default-position {
  max-height: 35rem;
  z-index: 50;
}
.scale-down-with-anim {
  max-height: 20rem;
}
.right-position-with-anim {
  transform: translateX(150px);
}
@media (max-width: 576px) {
  .scale-down-with-anim {
    max-height: 20rem;
    transform: translateX(185px);
  }
}
</style>
