<template>
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-4">
        <section id="specs-section">
          <div class="specs-section-start"></div>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="css">
#specs-section {
  min-height: 100vh;
}
</style>
