<template>
  <section id="faq-section" class="vertical-5-rem-margin">
    <div class="container">
      <div class="row mb-5">
        <div class="col">
          <span class="faq-title font-xxl"> FAQ </span>
        </div>
      </div>
      <div class="row d-flex justify-content-center">
        <div class="col-10">
          <div>
            <div class="accordion" id="accordionExample">
              <h4>SmartGoals General</h4>

              <div
                v-for="(smGeneralAccordionItem, index) in smGeneralAccordions"
                :key="'smGeneralAccordionItem' + index"
                class="accordion-item"
              >
                <h2
                  class="accordion-header"
                  :id="'smGeneralAccordionItem' + index"
                >
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    :data-bs-target="'#collapse' + index"
                    aria-expanded="true"
                    :aria-controls="'collapse' + index"
                  >
                    <div>{{ smGeneralAccordionItem.title }}</div>
                  </button>
                </h2>
                <div
                  :id="'collapse' + index"
                  class="accordion-collapse collapse"
                  :aria-labelledby="'smGeneralAccordionItem' + index"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <span v-html="smGeneralAccordionItem.content"></span>
                  </div>
                </div>
              </div>
            </div>

            <div class="accordion" id="smSmartRemoteAccordion">
              <h4>SmartRemote</h4>
              <div
                v-for="(
                  smSmartRemoteAccordionItem, index
                ) in smSmartRemoteAccordions"
                :key="'smSmartRemoteAccordionItem' + index"
                class="accordion-item"
              >
                <h2
                  class="accordion-header"
                  :id="'smSmartRemoteAccordionItem' + index"
                >
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    :data-bs-target="'#collapseSmartRemoteItem' + index"
                    aria-expanded="true"
                    :aria-controls="'collapseSmartRemoteItem' + index"
                  >
                    <div>{{ smSmartRemoteAccordionItem.title }}</div>
                  </button>
                </h2>
                <div
                  :id="'collapseSmartRemoteItem' + index"
                  class="accordion-collapse collapse"
                  :aria-labelledby="'smSmartRemoteAccordionItem' + index"
                  data-bs-parent="#smSmartRemoteAccordion"
                >
                  <div class="accordion-body">
                    <span v-html="smSmartRemoteAccordionItem.content"></span>
                  </div>
                </div>
              </div>
            </div>

            <div class="accordion" id="smSmartLightsAccordion">
              <h4>The SmartLights</h4>
              <div
                v-for="(
                  smSmartLightsAccordionItem, index
                ) in smSmartLightsAccordions"
                :key="'smSmartLightsAccordionItem' + index"
                class="accordion-item"
              >
                <h2
                  class="accordion-header"
                  :id="'smSmartLightsAccordionItem' + index"
                >
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    :data-bs-target="'#collapseSmartLightsItem' + index"
                    aria-expanded="true"
                    :aria-controls="'collapseSmartLightsItem' + index"
                  >
                    <div>{{ smSmartLightsAccordionItem.title }}</div>
                  </button>
                </h2>
                <div
                  :id="'collapseSmartLightsItem' + index"
                  class="accordion-collapse collapse"
                  :aria-labelledby="'smSmartLightsAccordionItem' + index"
                  data-bs-parent="#smSmartLightsAccordion"
                >
                  <div class="accordion-body">
                    <span v-html="smSmartLightsAccordionItem.content"></span>
                  </div>
                </div>
              </div>
            </div>

            <div class="accordion" id="smSmartChargerAccordion">
              <h4>The SmartCharger</h4>
              <div
                v-for="(
                  smSmartChargerAccordionItem, index
                ) in smSmartChargerAccordions"
                :key="'smSmartChargerAccordionItem' + index"
                class="accordion-item"
              >
                <h2
                  class="accordion-header"
                  :id="'smSmartChargerAccordionItem' + index"
                >
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    :data-bs-target="'#collapseSmartChargerItem' + index"
                    aria-expanded="true"
                    :aria-controls="'collapseSmartChargerItem' + index"
                  >
                    <div>{{ smSmartChargerAccordionItem.title }}</div>
                  </button>
                </h2>
                <div
                  :id="'collapseSmartChargerItem' + index"
                  class="accordion-collapse collapse"
                  :aria-labelledby="'smSmartChargerAccordionItem' + index"
                  data-bs-parent="#smSmartChargerAccordion"
                >
                  <div class="accordion-body">
                    <span v-html="smSmartChargerAccordionItem.content"></span>
                  </div>
                </div>
              </div>
            </div>

            <div class="accordion" id="smSmartAppAccordion">
              <h4>The SmartApp</h4>
              <div
                v-for="(smSmartAppAccordionItem, index) in smSmartAppAccordions"
                :key="'smSmartAppAccordionItem' + index"
                class="accordion-item"
              >
                <h2
                  class="accordion-header"
                  :id="'smSmartAppAccordionItem' + index"
                >
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    :data-bs-target="'#collapseSmartAppItem' + index"
                    aria-expanded="true"
                    :aria-controls="'collapseSmartAppItem' + index"
                  >
                    <div>{{ smSmartAppAccordionItem.title }}</div>
                  </button>
                </h2>
                <div
                  :id="'collapseSmartAppItem' + index"
                  class="accordion-collapse collapse"
                  :aria-labelledby="'smSmartAppAccordionItem' + index"
                  data-bs-parent="#smSmartAppAccordion"
                >
                  <div class="accordion-body">
                    <span v-html="smSmartAppAccordionItem.content"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  data() {
    return {
      smGeneralAccordions: [
        {
          title: "How do I setup a set of SmartGoals?",
          content: `Check out <a href="https://smartgoals.nl/setup-guide/#setup_smartgoals" target="_blank">this video</a>!`,
        },
        {
          title: "How long can I train with the SmartGoals?",
          content: `
            When fully charged, you can train at least <b>8 hours</b> with the SmartGoals. Depending on your training exercises it may even be more.
            If you also want to use the SmartRemote in combination with the SmartGoals SmartPhone App the SmartRemote has a battery time of roughly 6 hours.`,
        },
        {
          title: `Can I measure scores and receive data from the SmartGoals?`,
          content: `
            <b>Yes</b>, SmartGoals is a versatile training tool which can measure scores and timing. These scores can be stored in values to keep track of the players performance.
            Depending on your exercises you can also use the SmartGoals as score counter within matches and even display it on large TV screens.
          `,
        },
        {
          title: `What is the maximum range of the SmartGoals?`,
          content: `
            The maximum range of the SmartGoals is <b>30 meters/100 feet</b>.
            All the SmartGoals have a wireless transmitter to communicate with the other SmartGoals. Depending on your exercises you can even increase the training area to 50m.
          `,
        },
        {
          title: `What is the warranty when I buy a set?`,
          content: `
            From the day that you have bought a SmartGoals set, you will have a warranty period of exactly <b>one year</b> (365 days).
          `,
        },
        {
          title: `What is the difference between a Premium set and a Premium Double set?`,
          content: `
            A Premium set consists 12 SmartLights which all have the same frequency  (this can be frequency 1, 2, 3 or 4). This means that all the SmartLights in a Premium set can communicate with each other. Because of this the Premium set is delivered with one remote with the same frequency as the SmartLights.
            A Premium Double set also consists of 12 SmartLights. However the first 6 SmartLights do have an other frequency (for example frequency 1) then the last 6 SmartLights (for example frequency 2). This will give you the opportunity to let two groups train with different exercises, without the problem of two frequencies disturbing each other.
            It is NOT possible to work with one frequency if you've buyed a Premium Double set. Frequencies have been set and can't be changed. So this set will be delivered with two remotes (corresponding with the SmartLight frequencies).
          `,
        },
      ],
      smSmartRemoteAccordions: [
        {
          title: `How can I see if the SmartRemote is charging?`,
          content: `
            As soon as the SmartRemote is connected with the micro usb-plug in the socket, the orange light on the SmartRemote will blink. The <b>blinking</b> of the light is an indication the battery is charging.
          `,
        },
        {
          title: `How long does it take to fully charge the SmartRemote?`,
          content: `
            The SmartRemote has a Li-ion battery. This battery can be charged by connecting the SmartRemote with a micro usb-plug that's plugged in a socket. Charging the battery from completely empty into fully charged lasts <b>4 hours</b>.
          `,
        },
        {
          title: `How can I see if the battery is fully charged?`,
          content: `
            In case the SmartRemote is connected with the plug and the orange light is <b>constantly burning</b>, the battery is fully charged.
          `,
        },
        {
          title: `How long does the battery last?`,
          content: `
            A fully charged SmartRemote lasts 8 hours with a bluetooth connection. Without bluetooth connection you can use the SmartRemote for <b>16 hours</b>.
          `,
        },
        {
          title: `How can I see if the battery is almost empty?`,
          content: `
            As soon as the battery is almost empty the orange light is starting to <b>blink very fast</b>. From that moment on you can use the SmartRemote for about 30 minutes.
          `,
        },
        {
          title: `How can I see if the battery is empty?`,
          content: `
            The best way to check if the SmartRemote is empty, is to <b>push the bluetooth button</b>. If the blue light doesn't light up or isn't going to blink, the battery is empty. You have to charge it now.
          `,
        },
        {
          title: `How can I connect it with the SmartApp?`,
          content: `
            The normal procedure to connect your phone with the SmartRemote is as follows (see the video below):
           <br><ul>
              <li>
                1. <b>Install</b> the SmartGoals Sports app from the Android Play Store or App Store by searching for: “smartgoals sport”.
                    <ul>
                      <li>
                         Minimum Requirements for the mobile phone: iPhone 4s or Higher. (iOS 7.1 or higher)
                      </li>
                      <li>
 Minimum requirements for android phone: Bluetooth Low Energy module should be available for the phone. Android version 18.0 or higher.
                      </li>
                    </ul>
              </li>
              <li>
              2. <b>Open</b> the SmartGoals Sports App.
               <ul>
            <li>
            If Bluetooth is disabled, the App asks permission to activate the Bluetooth. Select Yes and allow the app to enable Bluetooth.
            </li>
            </ul>
              </li>

    <li>
      3. Get the SmartRemote and Press the <b>Bluetooth</b> button on the remote.
        <ul>
        <li>
          The Bluetooth led (blue led) will start blinking signalling that the SmartRemote is turning on Bluetooth. The Bluetooth module will be available for 5 seconds to make a connection.
        </li>
        </ul>
    </li>


    <li>
    4. The SmartGoals Sports app will automatically <b>start a connection</b> with the SmartRemote.
    <ul>
    <li>
    If succesfull the red bar on top of the screen will be removed and you can start using the SmartGoals Sports App. The SmartRemote will have the blue led on continuously.
    </li>
    </ul>
    </li>

    <li>
    5. Good luck and <b>have fun</b> with the SmartGoals Measuring App.
    </li>

           </ul>

          `,
        },
        {
          title: `I have problems with my bluetooth connection`,
          content: `
              If the connection procedure from above does not create a connection between the SmartRemote and the SmartGoals Sport App this might help you:
              <br><br>
              <ul>
              <li>
                  1. Check if the SmartRemote is functioning properly without the SmartRemote. Do the SmartLight respond to pressing the buttons on the SmartRemote?
              </li>

                <li>
f the battery of the SmartRemote is not empty. You can check this by pressing a random button on the remote. When the red signal led is blinking for a short period, the remote has enough battery power.              
</li>

                <li>
3. Check if the Bluetooth LED is lighting up when the Bluetooth button is pressed. Every time the Bluetooth Button is pressed, the Bluetooth led will keep blinking for 5 seconds.</li>

<li>
4. Check if the Bluetooth of your smartphone is enabled. If not, there will be a red bar in the SmartGoals Sport App indicating that: ''Bluetooth is disabled. Please turn it on...''
</li>

<li>
5. Check if the app can find / discover the SmartRemote in the Settings menu. If the remote can be discovered, the name: SG_Remote_[SETID] should be visible.
</li>

<li>
6. If the SmartRemote is discoverable by the SmartGoals App, give the phone more time to connect by continuously pressing and releasing the Bluetooth button of the SmartRemote a couple of times. This will enable the Bluetooth to have more time to make a connection.

</li>
              </ul>
              
              If an error occurs in number 5 or 6, please try to connect the SmartRemote with a different/other phone so we can eliminate that the phone has a Bluetooth malfunction. If all of the above does not help please contact us to give custom support. info@smartgoals.nl

          `,
        },
        {
          title: `The SmartRemote does not connect with the SmartGoals`,
          content: `
          If this occurs, please contact us by sending an e-mail to <b>info@smartgoals.nl</b>
          `,
        },
      ],
      smSmartLightsAccordions: [
        {
          title: `How long does it take to fully charge the SmartLights?`,
          content: `
          If the batteries of the SmartLights are completely empty it takes <b>12 hours</b> to fully charge them.

If the batteries are not completely empty it can take much less time. The SmartLights will indicate that they are fully charged when they light up blue inside the charger.
          `,
        },
        {
          title: `How long can I train with the SmartLights?`,
          content: `
            You can easily train for <b>8 hours</b> with a fully charged SmartLight.
          `,
        },
        {
          title: `The SmartLight keeps blinking when activated in the frame`,
          content: `
          When a SmartLight keeps blinking orange inside the frame the two SmartLights are not able to link their infrared sensors. 
          <br>This might be caused by the following reasons:
          <ul>
          <li>1. Something is <b>obstructing</b> the vision of the sensors. A ball, player or some other object is inside the SmartGoal.  (Remove the object that blocks the sensor vision.)</li>
           <li>2. The <b>sunlight</b> is shining directly into one sensor and overwhelms the sensor sensitivity. (Turn the SmartGoals away from the direct sunlight beam)</li>
            <li>3. One SmartLights <b>battery is empty</b>. Please charge everything and try again.</li>
          </ul>
          `,
        },
        {
          title: `The SmartLight does not turn on when attached frame`,
          content: `
          When a SmartLight does not turn on when attached to the frame the following two things could cause the problem:
          <ul>
            <li>1. The <b>battery</b> is completely empty. Please charge it before using it again.</li>
             <li>2. The frame does not have an <b>activation magnet</b>. Please try another SmartLight in the frame to see if this one works out.</li>
          </ul>
          `,
        },
        {
          title: `The SmartLight does not turn on in the charger`,
          content: `
            If a SmartLight doesn't turn on when plugged into the SmartCharger it might be because the electric connection is not good enough.
            <br>
            <ul>
              <li>1. Make sure the <b>power cable</b> is connected and powered with the power socket.</li>
               <li>2. Try <b>cleaning the metal parts</b> of the SmartLight as well as the <b>charger</b> to ensure electric connectivity.</li>
                <li>3. Try turning the SmartLight in a slightly <b>different position</b> until it makes contact and lights up.</li>
            </ul>
          `,
        },
        {
          title: `The SmartLight does not turn blue after charging`,
          content: `
          If the SmartLight does not turn blue after 12 hours of charging inside a SmartCharger nothing damaging is going on. The electric connectivity of the charger might not be 100% connecting.<br>
         
         <ul>
            <li>1. Please <b>clean</b> the metal parts of the SmartLight and SmartCharger.</li>
            <li>2. Reposition the SmartLight so that it has better electric connectivity.</li>
          </ul>
          
          (when a SmartLight lights up inside the charger you can be sure that the battery will be charged)
          `,
        },
        {
          title: `The SmartLight is blinking blue and shuts off`,
          content: `
            If a SmartLight blinks "blue" in the frame and shuts off, the <b>battery is empty</b>. Please charge your SmartLight before using it again.
          `,
        },
        {
          title: `A SmartLight is not reacting to the other lights`,
          content: `
            After setting up your SmartGoals for a specific exercise, always make sure you reset all the SmartGoals with the SmartRemote.
            <ul>
              <li>1. Press the '0' or 'reset'  button on the SmartRemote.</li>
              <li>2. All the SmartGoals should turn off.</li>
              <li>3. Press '+' blue or orange a couple of times to activate all SmartGoals.</li>
              <li>4. Press the '0' or 'reset' button on the SmartRemote.</li>
              <li>5. All the SmartGoals should turn off.</li>
            </ul>
          `,
        },
        {
          title: `Multiple SmartLights keep on blinking`,
          content: `
            If the SmartLights start to blink it means that something is <b>interfering</b> with the sensors of that SmartGoal. Please make sure nothing blocks the sight of an individual SmartGoal.
          `,
        },
      ],
      smSmartChargerAccordions: [
        {
          title: `A SmartLight does not charge in the SmartCharger`,
          content: `
            If a SmartLight doesn't turn on when plugged into the SmartCharger it might be because the electric connection is not good enough.
            <ul>
            <li>1. Make sure the <b>power cable</b> is connected and powered with the power socket.</li>
             <li>2. Try <b>cleaning</b> the metal parts of the SmartLight as well as the charger to ensure electric connectivity.</li>
              <li>3. Try turning the SmartLight in a slightly <b>different position</b> until it makes contact and lights up.</li>
            </ul>
          `,
        },
        {
          title: `The SmartLights do not activate on one side of the SmartCharger`,
          content: `
            If one side of the SmartCharger does not charge the SmartLights there might be a problem with the electronic connectivity of the charging plates.

            <ul>
                <li>1. Please check if the <b>screws</b> (plastic and metal) are all properly connected and firmly tightened.</li>
                <li>2. If not, screw them slightly <b>tighter</b> until they make contact with the metal plates.</li>
            </ul>
          `,
        },
        {
          title: `A SmartLight does not turn blue after fully charging`,
          content: `
            If the SmartLight does not turn blue after 12 hours of charging inside a SmartCharger nothing damaging is going on. The electric connectivity of the charger might be not 100% connecting.
            <ul>
              <li>1. Please <b>clean</b> the metal parts of the SmartLight and SmartCharger.</li>
               <li>2. <b>Turn</b> the SmartLight in a position that has better electric connectivity.</li>
            </ul>
            (when a SmartLight lights up inside the charger you can be sure that the battery will be charged)


          `,
        },
      ],
      smSmartAppAccordions: [
        {
          title: `How do I connect the SmartRemote to the SmartApp?`,
          content: `
            The normal procedure to connect your phone with the SmartRemote is as follows.
            <ul>
              <li>
                1. <b>Install</b> the SmartGoals Sports app from the Android Play Store or App Store by searching for: “SmartGoals sport”.
                  <ul>
                  <li>
                    Minimum Requirements for the mobile phone: iPhone 4s or Higher. (iOS 7.1 or higher)
                  </li>
                  <li>
                  Minimum requirements for android phone: Bluetooth Low Energy module should be available for the phone. Android version 18.0 or higher.
                </li>
                  </ul>
              </li>
              <li>
                2. <b>Open</b> the SmartGoals Sports App.
                <ul>
                <li>If Bluetooth is disabled, the App asks permission to activate the Bluetooth. Select Yes and allow the app to enable Bluetooth.
</li>
                </ul>
              
              </li>
              <li>
                3. Get the SmartRemote and Press the <b>Bluetooth</b> button on the remote.
                <ul>
                <li>The Bluetooth led (blue led) will start blinking signalling that the SmartRemote is turning on Bluetooth. The Bluetooth module will be available for 5 seconds to make a connection.
</li>
                </ul>
              </li>
              <li>
               4. The SmartGoals Sports app will automatically <b>start a connection</b> with the SmartRemote.
               <ul>
                  <li>If succesfull the red bar on top of the screen will be removed and you can start using the SmartGoals Sports App. The SmartRemote will have the blue led on continuously.</li>
               </ul>
              </li>
              <li>
              5.  Good luck and <b>have fun</b> with the SmartGoals Measuring App.
              </li>
            </ul>
          `,
        },
        {
          title: `The SmartApp does not seem to measure the right scores`,
          content: `
            One important thing to know is that the SmartGoals App communicates with the SmartRemote and the SmartRemote communicates with the SmartGoals and the other way around.

In order for the SmartGoals App to measure the right scores it is key that the SmartRemote "hears" all the signals that are send by the SmartGoals.

Keep the SmartRemote <b>always within 30 meters</b> of all SmartGoals to ensure a reliable timing circuit.
          `,
        },
      ],
    };
  },
};
</script>
<style lang="css">
#faq-section h4 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}
#faq-section {
  z-index: 50;
  position: relative;
}
.faq-title {
  position: relative;
  text-transform: uppercase;
}
.faq-title::after {
  content: "";
  position: absolute;
  left: 0;
  top: 55px;
  width: 50%;
  border-bottom: 3px solid #ed7009;
}
.accordion-body {
  text-align: left;
}
.accordion-button:not(.collapsed) {
  background-color: #d4d4d4 !important;
  color: #2c3e50 !important;
}
.accordion-button:focus {
  border-color: #ed7009 !important;
  box-shadow: 0 0 0 0.25rem rgb(237 112 9 / 25%) !important;
}
#faq-section a:any-link {
  text-decoration: none !important;
  color: #ed7009 !important;
}
</style>
