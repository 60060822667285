<template>
  <section id="testimonials-section">
    <div class="container">
      <div class="row">
        <div class="col d-flex justify-content-center">
          <span class="testimonials-title font-xxl"> Our clients </span>
        </div>
      </div>

      <div class="row mt-5 d-flex justify-content-center"></div>
      <div class="row my-5">
        <div class="col-12 col-sm-6 col-md-3 logo-box-1">
          <div class="our-partners-logo d-flex flex-column">
            <div class="logo-img-container">
              <img
                src="@/assets/sections/our-partners/Helmond-Sport.png"
                class="img-fluid"
                alt="Helmond Sport"
              />
            </div>
            <div class="logo-img-text-container">
              <span class="font-m">Helmond Sport</span>
            </div>
          </div>
        </div>

        <div class="col-12 col-sm-6 col-md-3 logo-box-2">
          <div class="our-partners-logo d-flex flex-column">
            <div class="logo-img-container">
              <img
                src="@/assets/sections/our-partners/KNVB.png"
                class="img-fluid"
                alt="KNVB"
              />
            </div>
            <div class="logo-img-text-container">
              <span class="font-m">KNVB</span>
            </div>
          </div>
        </div>

        <div class="col-12 col-sm-6 col-md-3 logo-box-3">
          <div class="our-partners-logo d-flex flex-column">
            <div class="logo-img-container">
              <img
                src="@/assets/sections/our-partners/Eindhoven-FC.png"
                class="img-fluid"
                alt="Eindhoven FC"
              />
            </div>
            <div class="logo-img-text-container">
              <span class="font-m">Eindhoven FC</span>
            </div>
          </div>
        </div>

        <div class="col-12 col-sm-6 col-md-3 logo-box-4">
          <div class="our-partners-logo d-flex flex-column">
            <div class="logo-img-container">
              <img
                src="@/assets/sections/our-partners/Paris-Academy.png"
                class="img-fluid"
                alt="Paris Academy"
              />
            </div>
            <div class="logo-img-text-container">
              <span class="font-m">Paris Academy</span>
            </div>
          </div>
        </div>

        <div class="col-12 col-sm-6 col-md-3 logo-box-5">
          <div class="our-partners-logo d-flex flex-column">
            <div class="logo-img-container">
              <img
                src="@/assets/sections/our-partners/Utrecht-FC.png"
                class="img-fluid"
                alt="Utrecht FC"
              />
            </div>
            <div class="logo-img-text-container">
              <span class="font-m">Utrecht FC</span>
            </div>
          </div>
        </div>

        <div class="col-12 col-sm-6 col-md-3 logo-box-6">
          <div class="our-partners-logo d-flex flex-column">
            <div class="logo-img-container">
              <img
                src="@/assets/sections/our-partners/Warberg-IC.png"
                class="img-fluid"
                alt="Warberg IC"
              />
            </div>
            <div class="logo-img-text-container">
              <span class="font-m">Warberg IC</span>
            </div>
          </div>
        </div>

        <div class="col-12 col-sm-6 col-md-3 logo-box-7">
          <div class="our-partners-logo d-flex flex-column">
            <div class="logo-img-container">
              <img
                src="@/assets/sections/our-partners/Philadelphia-Union.png"
                class="img-fluid"
                alt="Philadelphia Union"
              />
            </div>
            <div class="logo-img-text-container">
              <span class="font-m">Philadelphia Union</span>
            </div>
          </div>
        </div>

        <div class="col-12 col-sm-6 col-md-3 logo-box-8">
          <div class="our-partners-logo d-flex flex-column">
            <div class="logo-img-container">
              <img
                src="@/assets/sections/our-partners/Aberdeen-FC.png"
                class="img-fluid"
                alt="Aberdeen FC"
              />
            </div>
            <div class="logo-img-text-container">
              <span class="font-m">Aberdeen FC</span>
            </div>
          </div>
        </div>

        <div class="col-12 col-sm-6 col-md-3 logo-box-8">
          <div class="our-partners-logo d-flex flex-column">
            <div class="logo-img-container">
              <img
                src="@/assets/sections/our-partners/ajax-amsterdam.png"
                class="img-fluid"
                alt="Ajax Amsterdam FC"
              />
            </div>
            <div class="logo-img-text-container">
              <span class="font-m">Ajax Amsterdam</span>
            </div>
          </div>
        </div>

        <div class="col-12 col-sm-6 col-md-3 logo-box-8">
          <div class="our-partners-logo d-flex flex-column">
            <div class="logo-img-container">
              <img
                src="@/assets/sections/our-partners/psv-eindhoven.png"
                class="img-fluid"
                alt="Aberdeen FC"
              />
            </div>
            <div class="logo-img-text-container">
              <span class="font-m">PSV Eindhoven</span>
            </div>
          </div>
        </div>

        <div class="col-12 col-sm-6 col-md-3 logo-box-8">
          <div class="our-partners-logo d-flex flex-column">
            <div class="logo-img-container">
              <img
                src="@/assets/sections/our-partners/schalke.png"
                class="img-fluid"
                alt="Aberdeen FC"
              />
            </div>
            <div class="logo-img-text-container">
              <span class="font-m">Schalke FC</span>
            </div>
          </div>
        </div>

        <div class="col-12 col-sm-6 col-md-3 logo-box-8">
          <div class="our-partners-logo d-flex flex-column">
            <div class="logo-img-container">
              <img
                src="@/assets/sections/our-partners/dac.png"
                class="img-fluid"
                alt="Aberdeen FC"
              />
            </div>
            <div class="logo-img-text-container">
              <span class="font-m">DAC FC</span>
            </div>
          </div>
        </div>

        <div class="col-12 col-sm-6 col-md-3 logo-box-8">
          <div class="our-partners-logo d-flex flex-column">
            <div class="logo-img-container">
              <img
                src="@/assets/sections/our-partners/fenerbahce.png"
                class="img-fluid"
                alt="Aberdeen FC"
              />
            </div>
            <div class="logo-img-text-container">
              <span class="font-m">Fenerbahçe</span>
            </div>
          </div>
        </div>

        <div class="col-12 col-sm-6 col-md-3 logo-box-8">
          <div class="our-partners-logo d-flex flex-column">
            <div class="logo-img-container">
              <img
                src="@/assets/sections/our-partners/royal-antwerp.png"
                class="img-fluid"
                alt="Aberdeen FC"
              />
            </div>
            <div class="logo-img-text-container">
              <span class="font-m">Royal Antwerp</span>
            </div>
          </div>
        </div>

        <div class="col-12 col-sm-6 col-md-3 logo-box-8">
          <div class="our-partners-logo d-flex flex-column">
            <div class="logo-img-container">
              <img
                src="@/assets/sections/our-partners/ohl-leuven.png"
                class="img-fluid"
                alt="Aberdeen FC"
              />
            </div>
            <div class="logo-img-text-container">
              <span class="font-m">OHL Leuven</span>
            </div>
          </div>
        </div>

        <div class="col-12 col-sm-6 col-md-3 logo-box-8">
          <div class="our-partners-logo d-flex flex-column">
            <div class="logo-img-container">
              <img
                src="@/assets/sections/our-partners/san-diego-1904.png"
                class="img-fluid"
                alt="Aberdeen FC"
              />
            </div>
            <div class="logo-img-text-container">
              <span class="font-m">San Diego 1904</span>
            </div>
          </div>
        </div>

        <div class="col-12 col-sm-6 col-md-3 logo-box-8">
          <div class="our-partners-logo d-flex flex-column">
            <div class="logo-img-container">
              <img
                src="@/assets/sections/our-partners/derby-county.png"
                class="img-fluid"
                alt="Aberdeen FC"
              />
            </div>
            <div class="logo-img-text-container">
              <span class="font-m">Derby County</span>
            </div>
          </div>
        </div>

        <div class="col-12 col-sm-6 col-md-3 logo-box-8">
          <div class="our-partners-logo d-flex flex-column">
            <div class="logo-img-container">
              <img
                src="@/assets/sections/our-partners/dvsc.png"
                class="img-fluid"
                alt="Aberdeen FC"
              />
            </div>
            <div class="logo-img-text-container">
              <span class="font-m">Debreceni VSC</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {};
</script>
<style lang="css">
.testimonials-title {
  text-transform: uppercase;
  position: relative;
}
.testimonials-title::after {
  content: "";
  position: absolute;
  left: 0;
  top: 55px;
  width: 50%;
  border-bottom: 3px solid #ed7009;
}
.testimonials-quote {
  color: #777777;
  font-size: 1rem !important;
}
.icon {
  color: #ed7009;
}
iframe {
  z-index: 12 !important;
}
.video-title {
  overflow: hidden;
  white-space: nowrap;
}
.video-subtitle {
  min-height: 0rem;
  overflow: hidden;
  white-space: nowrap;
}
@media (min-width: 768px) {
  .video-subtitle {
    min-height: 3rem;
    overflow: hidden;
    white-space: nowrap;
  }
}
.our-partners-title {
  font-weight: 500;
}
.logo-img-container {
  padding: 10px;
  min-height: 8rem;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  z-index: 12 !important;
}
.logo-img-container img {
  height: 8rem;
}
.logo-img-text-container {
  padding: 5px;
  min-height: 5rem;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.carousel {
  z-index: 10;
}
.carousel-indicators {
  bottom: -3rem !important;
}
.blockquote-footer {
  margin-top: 1.5rem !important;
}

.carousel-inner {
  display: flex;
  align-items: center;
  left: 50%;
  transform: translateX(-50%);
  width: 80% !important;
  min-height: 10rem;
}
.carousel-inner span {
  font-size: 2rem;
  font-style: italic;
}
.logo-box-1,
.logo-box-2,
.logo-box-3,
.logo-box-4,
.logo-box-5,
.logo-box-6,
.logo-box-7,
.logo-box-8 {
}
</style>
