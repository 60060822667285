<template>
  <div class="container">
    <footer class="pt-5">
      <div
        class="row d-flex justify-content-sm-around justify-content-between vertical-5-rem-margin"
      >
        <div class="col-12 col-sm-2 mb-4 mb-md-0">
          <h5>Products</h5>
          <ul class="nav flex-column">
            <li class="nav-item mb-2">
              <a href="#home-section" class="nav-link p-0 text-muted"
                >SmartGoals</a
              >
            </li>
            <li class="nav-item mb-2">
              <router-link class="nav-link p-0 text-muted" to="/skillgames"
                >SkillGames</router-link
              >
            </li>
            <li class="nav-item mb-2">
              <a
                href="https://skillcourts.nl/en/"
                target="blank"
                class="nav-link p-0 text-muted"
                >SkillCourts</a
              >
            </li>
          </ul>
        </div>

        <div class="col-12 col-sm-2 mb-4 mb-md-0">
          <h5>Important links</h5>
          <ul class="nav flex-column">
            <li class="nav-item mb-2">
              <a
                href="https://smartgoalstraining.com/skillgames/#/login"
                class="nav-link p-0 text-muted"
                target="blank"
                >SkillGames Login</a
              >
            </li>
            <li class="nav-item mb-2">
              <a
                href="https://smartgoalstraining.com"
                class="nav-link p-0 text-muted"
                target="blank"
                >Exercise Database</a
              >
            </li>
            <li class="nav-item mb-2">
              <router-link class="nav-link p-0 text-muted" to="/faq"
                >FAQ</router-link
              >
            </li>
            <li class="nav-item mb-2">
              <a
                href="https://smartgoalstraining.com/General_Terms_and_Conditions_of_Sale_SmarGoalsBV.pdf"
                target="blank"
                class="nav-link p-0 text-muted"
                >Data Privacy</a
              >
            </li>
          </ul>
        </div>

        <div class="col-12 col-md-6 flex-column">
          <div class="row mb-1">
            <div class="col">
              <h5>Official distributors</h5>
            </div>
          </div>
          <div class="partner-logos row d-flex align-items-center justify-content-center mb-4">
            <div class="col-8 col-md-4 my-3 my-md-0">
              <a href="https://whsports.nl" target="blank">
                 <img src="@/assets/partners/wh.svg" class="img-fluid" alt="WH">
              </a>
            </div>
            <div class="col-8 col-md-4 my-3 my-md-0">
                 <a href="https://www.janssen-fritsen.nl" target="blank">
                  <img src="@/assets/partners/janssen.png" class="img-fluid" alt="Janssen Fritsen">
                 </a>
            </div>
            <div class="col-8 col-md-4 my-3 my-md-0">
                 <a href="https://www.sportlander.hu/" target="blank">
                  <img src="@/assets/partners/sportlander.png" class="img-fluid" alt="Sportlander">
                 </a>
            </div>
          </div>
          <div class="row mb-5">
            <div class="col">
              <form @submit="checkSubscriptionForm">
            <p>Let’s do business together.</p>
            <div class="d-flex w-100 gap-2">
              <label for="newsletter1" class="visually-hidden"
                >Email address</label
              >
              <input
                id="newsletterinput"
                v-model="newsletterinput"
                type="text"
                class="form-control"
                placeholder="Email address"
              />
              <button class="subscribe-btn" type="submit">Send</button>
            </div>
          </form>
          <div v-if="newslettersent" class="my-4">
            <b>E-mail sent successfully! We will contact you soon, thanks!</b>
          </div>
            </div>
          </div>
         
        </div>
      </div>

      <div class="d-flex justify-content-between pt-4 mt-4 border-top">
        <p>© 2022 SmartGoals</p>
        <ul class="d-flex">
          <li class="ms-3">
            <a href="https://www.linkedin.com/company/smartgoals/" target="_blank">
              <font-awesome-icon :icon="['fab', 'linkedin']" size="lg"
            /></a>
          </li>
          <li class="ms-3">
            <a
              href="https://www.instagram.com/smartgoalsfootball/"
              target="_blank"
            >
              <font-awesome-icon :icon="['fab', 'instagram-square']" size="lg"
            /></a>
          </li>
          <li class="ms-3">
            <a href="https://www.facebook.com/smartgoalssport/" target="_blank">
              <font-awesome-icon :icon="['fab', 'facebook-square']" size="lg"
            /></a>
          </li>
          <li class="ms-3">
            <a href="https://www.youtube.com/c/SmartgoalsNl" target="_blank">
              <font-awesome-icon :icon="['fab', 'youtube-square']" size="lg"
            /></a>
          </li>
        </ul>
      </div>
    </footer>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      newsletterinput: "",
      newslettersent: false,
    };
  },
  methods: {
    checkSubscriptionForm(e) {
      e.preventDefault();
      this.newslettersent = true;
      const subscriptionData = {
        first_name: "Not given",
        last_name: "Not given",
        email: this.newsletterinput,
        message: `${this.newsletterinput} would like to become a partner (affiliate)!`,
      };

      axios({
        method: "post",
        url: "https://skillcourts-api.skillgam.es/api/contact",
        data: subscriptionData,
        headers: { "Content-Type": "application/json" },
      })
        .then(() => {})
        .catch((err) => {
          console.log(err);
        });

      setTimeout(() => {
        (this.newsletterinput = ""), (this.newslettersent = false);
      }, 3000);
    },
  },
};
</script>

<style lang="css">
ul {
  list-style: none;
}
li a {
  color: #ed7009;
}
.subscribe-btn {
  color: #fff;
  background-color: #ed7009;
  border-radius: 40px;
  height: 44px;
  border: none;
  padding: 10px 30px;
}
footer a:any-link {
  color: #ed7009;
  text-decoration: none;
}
.partner-logos img {
  max-height: 8rem;
}
</style>
