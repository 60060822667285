<template>
  <section id="home-section" class="d-flex align-items-center">
    <div class="container">
      <div
        class="welcome-box d-flex flex-column align-items-center justify-content-center"
      >
        <div class="welcome-title observable">
          <span
            >Be better, <br />train
            <span class="orange-text">smarter</span></span
          >
        </div>
        <div class="welcome-subtitle mx-auto my-4">
          <span class="text-center">
            Take your training to the next level! SmartGoals is the number one
            interactive training solution in the world!
          </span>
          <form class="d-flex justify-content-center mt-4">
            <a class="sm-orange-btn" href="https://www.youtube.com/watch?v=4WQeiYBRhrY" target="_blank">SkillGames Video</a>
          </form>
          <br>
          <br>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {};
</script>
<style lang="css" scoped>
#home-section {
  min-height: calc(100vh - 250px);
}
.welcome-box {
  margin-top: 80px;
}
.welcome-title span {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 2rem;
  color: #1d1d1f;
}
.welcome-subtitle {
  max-width: 400px;
}
.welcome-subtitle span {
  color: #1d1d1f;
  font-size: 1rem;
}
.welcome-subtitle a:any-link {
  text-decoration: none;
  color: white;
}
</style>
