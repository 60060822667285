var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper",staticStyle:{"height":"100%"}},[_c('Header',{staticStyle:{"position":"fixed","width":"100%"},attrs:{"id":"welcome-section","visitFromBlogPage":true}}),_c('transition',{attrs:{"appear":""}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row mt-5"},[_c('div',{staticClass:"col-12 mt-5"},[_c('h4',[_vm._v(" Posts under the following label: "+_vm._s(_vm.$route.params.labelName)+" ")])])]),_c('div',{staticClass:"row row-cols-1 row-cols-md-3 g-4 mb-5"},_vm._l((_vm.listOfPosts),function(post){return _c('div',{key:post.id,staticClass:"col"},[_c('div',{staticClass:"card h-100 mt-3 shadow-sm"},[_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"card-img-container",staticStyle:{},style:({
                  backgroundImage:
                    post.image == 'no-picture'
                      ? 'url(' + _vm.defaultImg + ')'
                      : 'url(' + post.image + ')',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center'
                })}),_c('div',{staticClass:"pt-3"},[_c('a',{staticClass:"category-link",attrs:{"href":'/blog/categories/' + post.category}},[_vm._v(_vm._s(post.category))])])]),_c('div',{staticClass:"card-body"},[_c('router-link',{attrs:{"to":("/blog/posts/" + (post.url))}},[_c('h5',{staticClass:"card-title"},[_vm._v(" "+_vm._s(post.title)+" ")])])],1),_c('div',{staticClass:"card-footer d-flex align-items-center"},[_c('div',{staticClass:"d-flex"},[_c('img',{staticClass:"author-avatar",attrs:{"src":post.author.avatar,"alt":"Author avatar"}})]),_c('div',{staticClass:"d-flex px-2"},[_c('a',{attrs:{"href":post.author.social,"target":"_blank"}},[_c('span',{staticClass:"py-2"},[_vm._v(_vm._s(post.author.name))])])])])])])}),0)])]),_c('Footer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }