<template>
  <section id="concept-section">
    <div class="container">
      <div class="row">
        <div class="col">
          <div class="product-box">
            <div class="product-title font-xxl">
              <span
                >What is <br />
                <span class="orange-text">SmartGoals</span></span
              >
            </div>
            <div class="product-subtitle font-s mt-4">
              <span>
                SmartGoals is a versatile training system, that can be used for
                numerous sports activities, such as football, hockey, physical
                education and even sports rehabilitation.
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="sm-tower-container">
      <smart-goals-tower></smart-goals-tower>
    </div>
    <div class="container d-flex align-items-start">
      <specs></specs>
    </div>
  </section>
</template>
<script>
import SmartGoalsTower from "./SmartGoalsTower.vue";
import Specs from "./Specs.vue";

export default {
  components: { SmartGoalsTower, Specs },
};
</script>
<style lang="css" scoped>
#concept-section {
  position: relative;
  height: 200vh;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  text-align: left;
}
.sm-tower-container {
  position: sticky;
  top: 25vh;
  width: 100%;
  height: 20rem;
}
.product-title {
  color: #1d1d1f;
  text-transform: uppercase;
}
.product-subtitle {
  color: #1d1d1f;
}
@media (max-width: 576px) {
  .product-box {
    transition: all ease-in-out 2000ms;
    opacity: 0;
    margin-top: 25vh;
    max-width: 50vw;
    position: absolute;
  }
}
@media (max-width: 768px) and (min-width: 577px) {
  .product-box {
    transition: all ease-in-out 2000ms;
    opacity: 0;
    margin-top: 25vh;
    max-width: 30vw;
    position: absolute;
  }
}
@media (max-width: 992px) and (min-width: 769px) {
  .product-box {
    transition: all ease-in-out 2000ms;
    opacity: 0;
    margin-top: 25vh;
    max-width: 30vw;
    position: absolute;
  }
}
@media (min-width: 992px) {
  .product-box {
    transition: all ease-in-out 2000ms;
    opacity: 0;
    margin-top: 25vh;
    max-width: 25vw;
    position: absolute;
  }
}
</style>
